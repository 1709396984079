import { Dispatch } from 'react';

export interface IPropertyDataContext {
    state: IPropertyData;
    dispatch: Dispatch<IPropertyDataAction>;
}

interface MetricFilter {
    propertyTypes: string[];
    beds: number;
}

export interface IPropertyData {
    pins: any[];
    properties: Map<string, any> ;
    loading: boolean;
    trackViewport: boolean;
    metrics: any[];
    showMetrics: boolean;
    psfDist: IPsfDist | null;
    selectedProperty: any | null;
    showPropertyModal: boolean;
    currentTags: Map<string, any>;
    viewOnMapId: string | null;
    metricFilter: MetricFilter;
    parsedProperties: Map<string, any>;
    parsedPins: any[];
}

export interface IPropertyDataAction {
    type: string;
    payload?: any;
}

export interface IPsfDist {
    total: number;
    min: number;
    max: number;
    bins: any[];
    percentiles: number[];
}

export const PropertyTypes = [
    "Apartment",
    "Barn Conversion",
    "Block of Apartments",
    "Bungalow",
    "Chalet",
    "Character Property",
    "Cottage",
    "Country House",
    "Detached",
    "Detached Bungalow",
    "Detached Villa",
    "Duplex",
    "End of Terrace",
    "Finca",
    "Flat",
    "Gite",
    "Ground Flat",
    "Ground Maisonette",
    "House",
    "Link Detached House",
    "Maisonette",
    "Mews",
    "Not Specified",
    "Penthouse",
    "Semi-Detached",
    "Semi-Detached Bungalow",
    "Serviced Apartments",
    "Studio",
    "Terraced",
    "Terraced Bungalow",
    "Town House",
    "Triplex"
  ];