import React, { useState } from 'react';
import { Box, IconButton, Link } from '@mui/joy';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ImageCarousel = ({ images, width='200px', height='200px' }: { images: string[], width?: string, height?: string }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const isLastSlide = currentIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const multiple = images.length > 1;

    const currentImage = images[currentIndex];

    const ImageContent = () => (
        <Box
            component='img'
            src={currentImage}
            sx={{
                width: '100%',
                maxHeight: height,
                minHeight: height,
                objectFit: 'contain',
                objectPosition: 'center',
                transition: 'opacity 0.3s ease-in-out',
            }}
        />
    );

    return (
        <Box component='div' sx={{ position: 'relative', width: '100%', height: '100%' }}>
            <ImageContent />
            {multiple && <IconButton
                onClick={goToPrevious}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '8px',
                    transform: 'translate(0, -50%)',
                    zIndex: 52,
                }}
            >
                <ArrowBackIosNewIcon />
            </IconButton>}
            {multiple && <IconButton
                onClick={goToNext}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '8px',
                    transform: 'translate(0, -50%)',
                    zIndex: 52,
                }}
            >
                <ArrowForwardIosIcon />
            </IconButton>}
        </Box>
    );
};

export default ImageCarousel;