import { FC, ReactNode, createContext, useReducer } from 'react';
import { IFilter, IFilterAction, IFilterContext } from '../types/filter.type';

const initialFilter: IFilter = {
    min_area: 0,
    max_area: 1_000_000_000,
    include_null_area: true,

    min_price: 0,
    max_price: 1_000_000_000,
    include_null_price: true,

    min_psf: 0,
    max_psf: 1_000_000_000,
    include_null_psf: true,

    min_percent_leased: 0,
    max_percent_leased: 100,
    include_null_percent_leased: true,

    listedGrade: [],
    listed: ['no', 'na'],

    article4: ['no', 'na'],
    tenure: ['freehold', 'virtual freehold'],
    propertyTypes: [],

    tags: [],
    saved: ['yes', 'no'],
    last_seen: null,

    viewport: null,

    sortBy: 'date_added',
    sortOrder: 'DESC',

    startDate: new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date().toISOString(),
}

const filterReducer = (state: IFilter, action: IFilterAction): IFilter => {
    switch (action.type) {
        case 'SET_MIN_AREA':
            console.log(action.payload);
            if (action.payload === undefined || action.payload === null || isNaN(action.payload)) {
                return { ...state, min_area: 0 };
            }
            return { ...state, min_area: action.payload };
        case 'SET_MAX_AREA':
            return { ...state, max_area: action.payload };
        case 'SET_INCLUDE_NULL_AREA':
            return { ...state, include_null_area: action.payload };

        case 'SET_MIN_PRICE':
            return { ...state, min_price: action.payload };
        case 'SET_MAX_PRICE':
            return { ...state, max_price: action.payload };
        case 'SET_INCLUDE_NULL_PRICE':
            return { ...state, include_null_price: action.payload };

        case 'SET_MIN_PSF':
            return { ...state, min_psf: action.payload };
        case 'SET_MAX_PSF':
            return { ...state, max_psf: action.payload };
        case 'SET_INCLUDE_NULL_PSF':
            return { ...state, include_null_psf: action.payload };

        case 'SET_MIN_PERCENT_LEASED':
            return { ...state, min_percent_leased: action.payload };
        case 'SET_MAX_PERCENT_LEASED':
            return { ...state, max_percent_leased: action.payload };
        case 'SET_INCLUDE_NULL_PERCENT_LEASED':
            return { ...state, include_null_percent_leased: action.payload };

        case 'SET_LISTED_GRADE':
            return { ...state, listedGrade: action.payload };
        case 'SET_LISTED':
            return { ...state, listed: action.payload };

        case 'SET_ARTICLE4':
            return { ...state, article4: action.payload };
        case 'SET_TENURE':
            return { ...state, tenure: action.payload };
        case 'SET_PROPERTY_TYPES':
            return { ...state, propertyTypes: action.payload };

        case 'SET_TAGS':
            return { ...state, tags: action.payload };
        case 'SET_SAVED':
            return { ...state, saved: action.payload };
        case 'SET_LAST_SEEN':
            return { ...state, last_seen: action.payload };

        case 'SET_VIEWPORT':
            return { ...state, viewport: action.payload };

        case 'SET_SORT_BY':
            return { ...state, sortBy: action.payload };
        case 'SET_SORT_ORDER':
            return { ...state, sortOrder: action.payload };

        case 'SET_DATE_RANGE':
            const { startDate, endDate } = action.payload;
            return { ...state, startDate, endDate };
        case 'RESET':
            return initialFilter;
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const FilterContext = createContext<IFilterContext | undefined>(undefined);

const FilterProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(filterReducer, initialFilter);
    return (
        <FilterContext.Provider value={{ state, dispatch }} >
            {children}
        </FilterContext.Provider>
    );
};


export default FilterProvider;
export { filterReducer, initialFilter, FilterContext, FilterProvider };