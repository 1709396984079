import React, { FC, useRef, useState } from 'react';
import { Input, InputProps } from '@mui/joy';

interface DebounceProps {
  handleDebounce: (value: string) => void;
  debounceTimeout: number;
}

type DebouncedInputProps = InputProps & DebounceProps;

const DebouncedInput: FC<DebouncedInputProps> = ({
  handleDebounce,
  debounceTimeout,
  defaultValue = '',
  ...rest
}) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const [inputValue, setInputValue] = useState<number | string>(rest.value?.toString() || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      handleDebounce(event.target.value);
    }, debounceTimeout);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    console.log('empty', event.target.value);
    if (event.target.value === '') {
      setInputValue(defaultValue as string | number);
      handleDebounce(defaultValue.toString());
    }
  }

  return <Input {...rest} value={inputValue} onChange={handleChange} onBlur={handleBlur}/>;
};

export default DebouncedInput;