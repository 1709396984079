import { RefObject, useState, useRef, useEffect, useCallback } from 'react'
import { MvtSourceOptions, ClickOptions } from './layer.config'
import { getMsoaMetrics, getMsoaPsfDist, getMsoaData } from '../api/metrics.api';
import usePropData from './usePropData';

const useLayers = (mapRef?: RefObject<google.maps.Map>) => {

    const { state, dispatch } = usePropData();
    const mvtSourceRef = useRef<any | null>(null);
    const clickListenerRef = useRef<google.maps.MapsEventListener | null>(null);

    const [activeLayers, setActiveLayers] = useState<Map<string, boolean>>(new Map([
        ['msoa_4326', false],
        ['nhle_4326', false],
        ['a4_4326', false],
        ['terrain', false]
    ]));

    // const [showMetrics, setShowMetrics] = useState<boolean>(false);

    const handleVectorTileClick = async (event: any) => {
        const feature = event.feature;

        if (feature && mvtSourceRef.current) {
            const { layer, name } = feature.properties;

            switch (layer) {
                case 'msoa_4326':
                    const currSelected = mvtSourceRef.current.getSelectedFeatures()
                        .filter((f: any) => f.properties.layer === 'msoa_4326');
                    
                    if (currSelected.length === 0) {
                        dispatch({ type: 'SET_METRICS', payload: [] });
                        dispatch({ type: 'SET_SHOW_METRICS', payload: false });
                        return;
                    }

                    dispatch({ type: 'SET_SHOW_METRICS', payload: true });

                    const nameMap = new Map(currSelected.map((f: any) => [f.featureId, f.properties.name]));

                    const sixMonthsAgo = new Date();
                    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

                    const msoaCodes = currSelected.map((f: any) => f.featureId);
                    
                    const metrics = await getMsoaMetrics(
                        msoaCodes, sixMonthsAgo.toISOString(), new Date().toISOString(), state.metricFilter.propertyTypes, state.metricFilter.beds
                    );

                    metrics.forEach((metric: any) => {
                        const { code } = metric;
                        metric.name = nameMap.get(code);
                    })
                    dispatch({ type: 'SET_METRICS', payload: metrics });

                    const psfDist = await getMsoaPsfDist(msoaCodes);
                    dispatch({ type: 'SET_PSF_DIST', payload: psfDist });
                    break;

                case 'nhle_4326':
                    break
            }
        }
    };

    // reattach click listener when metric filter changes
    // this is since handleVectorTileClick is passed to third party library
    useEffect(() => {
        if (mapRef && mapRef.current && mvtSourceRef.current && clickListenerRef.current) {
            google.maps.event.removeListener(clickListenerRef.current);
            clickListenerRef.current = mapRef.current.addListener('click', (event: google.maps.MapMouseEvent) => {
                mvtSourceRef.current.onClick(event, handleVectorTileClick, ClickOptions)
            });
        }

    }, [state.metricFilter]);

    useEffect(() => {
        if (mapRef !== undefined && mapRef.current) {
            if (Array.from(activeLayers.values()).some(layer => layer)) {

                // initialise mvtSource
                if (!mvtSourceRef.current) {
                    mvtSourceRef.current = new window.MVTSource(mapRef.current, MvtSourceOptions);
                    mapRef.current.overlayMapTypes.insertAt(0, mvtSourceRef.current);
                    clickListenerRef.current = mapRef.current.addListener('click', (event: google.maps.MapMouseEvent) => {
                        mvtSourceRef.current.onClick(event, handleVectorTileClick, ClickOptions)
                    });
                }

                // !: all selected layers visibie
                mvtSourceRef.current.setVisibleLayers(Array.from(activeLayers.keys()).filter(layer => activeLayers.get(layer)));
                // console.log(mvtSourceRef.current)
                const bothContained = ['msoa_4326', 'nhle_4326'].every(layer => activeLayers.get(layer));
                mvtSourceRef.current.setClickableLayers(bothContained
                    ? ['msoa_4326']
                    : Array.from(activeLayers.keys())
                        .filter(layer => ['msoa_4326', 'nhle_4326'].includes(layer)));
            } else {
                // remove layers if none are active to reduce backend calls
                if (mvtSourceRef.current) {
                    mapRef.current.overlayMapTypes.removeAt(0);
                    mvtSourceRef.current = null;
                    if (clickListenerRef.current) {
                        google.maps.event.removeListener(clickListenerRef.current);
                        clickListenerRef.current = null;
                    }
                }
            }
        }
    }, [mapRef, activeLayers]);

    const toggleLayer = (layer: string) => {
   
        setActiveLayers(prevState => {
            const newActiveLayers = new Map(prevState);
            const newState = !newActiveLayers.get(layer);
            if (layer === 'msoa_4326' && !newState) {
                if (mvtSourceRef.current) {
                    mvtSourceRef.current.deselectAllFeatures();
                    dispatch({ type: 'SET_SHOW_METRICS', payload: false });
                }
            }
            newActiveLayers.set(layer, !newActiveLayers.get(layer));
            return newActiveLayers;
        });
    }

    return { toggleLayer };
}

export default useLayers;