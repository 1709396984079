import { FC, useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Typography from '@mui/joy/Typography';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Checkbox from '@mui/joy/Checkbox';
import Chip from '@mui/joy/Chip';
import Stack from '@mui/joy/Stack';
import CloseRounded from '@mui/icons-material/CloseRounded';
import DialogTitle from '@mui/joy/DialogTitle';
import Divder from '@mui/joy/Divider';
import ChipDelete from '@mui/joy/ChipDelete';
import Input from '@mui/joy/Input';
import { styled } from '@mui/joy/styles';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRounded from '@mui/icons-material/WarningRounded';

import { ListedGrade as LG, YesNoNa, Tenure } from '../types/filter.type';
import useFilter from '../hooks/useFilter';
import usePropData from '../hooks/usePropData';
import DebouncedInput from './DebouncedInput';
import NumericFormatAdapter from '../adapters/numericFormatAdapter';
import { initialFilter } from '../contexts/Filter';
import { getPins } from '../api/properties.api';
import { DialogContent } from '@mui/joy';
import DialogActions from '@mui/joy/DialogActions';
import { deleteTag } from '../api/tags.api';
import { getProperties } from '../api/properties.api';
import { serializeComplexTypes, getCurrentTimestamp } from '../api/utils';
import { writeFile, utils } from 'xlsx';

const FilterContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`,
  borderRadius: '1%',
  padding: theme.spacing(2), // Add some padding
}));

const FormSection = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colorSchemes.light.palette.divider}`,
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));

const Hyphen = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '5px',
  margin: theme.spacing(0, -1.5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  [`&[aria-pressed='true']`]: {
    ...theme.variants.outlinedActive.primary,
    borderColor: theme.vars.palette.primary.outlinedHoverBorder,
  },
}));

const StyledButtonGroupContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, auto)',
  gap: theme.spacing(2),
  justifyContent: 'start',
}));

type ListedGradeType = {
  [key in LG]: boolean;
} & {
  [key in YesNoNa]: boolean;
};

const FilterMenu: FC = () => {
  const { state: filter, dispatch: dispatchFilter } = useFilter();
  const { state: data, dispatch: dispatchData } = usePropData();

  const [includeAllTime, setIncludeAllTime] = useState(true);
  const [number, setNumber] = useState(3);
  const [unit, setUnit] = useState<'days' | 'weeks' | 'months' | 'years'>('months');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!includeAllTime) {
      const currentTimestamp = Math.floor(new Date().getTime() / 1000); // seconds

      let secsToSub;
      switch (unit) {
        case 'days':
          secsToSub = number * 24 * 60 * 60;
          break;
        case 'weeks':
          secsToSub = number * 7 * 24 * 60 * 60;
          break;
        case 'months':
          secsToSub = number * 30 * 24 * 60 * 60;
          break;
        case 'years':
          secsToSub = number * 365 * 24 * 60 * 60;
          break;
        default:
          secsToSub = currentTimestamp;
      }

      if (secsToSub !== undefined) {
        dispatchFilter({ type: 'SET_LAST_SEEN', payload: currentTimestamp - secsToSub });
      }
    }
  }, [includeAllTime, number, unit]);

  const [listedGrade, setListedGrade] = useState<ListedGradeType>({
    [LG.I]: false,
    [LG.II]: false,
    [LG.II_STAR]: false,
    [YesNoNa.YES]: false,
    [YesNoNa.NO]: true,
    [YesNoNa.NA]: true,
  });
  const listedButtonsDisabled = !listedGrade[YesNoNa.YES];

  const handleListedGradeClick = (option: LG | YesNoNa) => {
    setListedGrade((prev) => {
      const updated = { ...prev, [option]: !prev[option] };
      console.log(updated);
      if (Object.values(LG).includes(option as LG)) {
        dispatchFilter({
          type: 'SET_LISTED_GRADE',
          payload: Object.keys(updated).filter((key) => updated[key as LG]),
        });
      } else {
        dispatchFilter({
          type: 'SET_LISTED',
          payload: Object.keys(updated).filter((key) => updated[key as YesNoNa]),
        });
      }
      return updated;
    });
  };

  const handlePropertyType = (event: React.SyntheticEvent | null, newValue: Array<string> | null) => {
    if (newValue) {
      dispatchFilter({ type: 'SET_PROPERTY_TYPES', payload: newValue });
    }
  };

  const handleDeleteTag = async (tagId: string) => {
    setLoading(true);
    // const propertyIds = await deleteTag(tagId);
    const propertyIds: any[] = [];
    dispatchData({ type: 'REMOVE_TAG', payload: { propIds: propertyIds, tagId } });
    setLoading(false);
    setOpen(false);
  };

  const handleDownload = async () => {
    const ids = data.pins.map((pin: any) => pin.property_id);
    const properties = await getProperties(ids);
    const processed = properties.map((property: any) => serializeComplexTypes(property));
    const ws = utils.json_to_sheet(processed);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Properties');
    writeFile(wb, `properties_${getCurrentTimestamp()}.xlsx`);
  };

  return (
    <FilterContainer>
      <Modal open={open}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRounded />
            <Typography level="body-sm">Confirmation</Typography>
          </DialogTitle>
          <Divder />
          <DialogContent>
            <Typography level="body-sm">{error.split('@')[0]}</Typography>
          </DialogContent>
          <DialogActions>
            <Button color="neutral" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button color="danger" loading={loading} onClick={() => handleDeleteTag(error.split('@')[1])}>
              Delete
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <form onSubmit={() => console.log('form submitted')}>
        <FormSection>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={2} sx={{ mb: -1 }}>
            <Button
              fullWidth
              variant="outlined"
              color="warning"
              onClick={() => dispatchFilter({ type: 'RESET', payload: null })}
            >
              reset
            </Button>
            <Button
              fullWidth
              variant="solid"
              loading={data.loading}
              disabled={data.loading}
              onClick={async () => {
                dispatchData({ type: 'SET_LOADING', payload: true });
                dispatchData({ type: 'CLEAR_PROPERTIES' });
                const pins = await getPins(filter);
                dispatchData({ type: 'SET_PINS', payload: pins });
                dispatchData({ type: 'SET_LOADING', payload: false });
              }}
            >
              search
            </Button>
          </Box>
          <Button fullWidth variant="outlined" onClick={handleDownload}>
            Download
          </Button>
        </FormSection>

        <SectionTitle level="body-sm">tags</SectionTitle>
        <FormSection sx={{ gap: 1 }}>
          {data.currentTags.size > 0 ? (
            Array.from(data.currentTags.values()).map((tag: any) => (
              <Chip
                key={tag.tag_id}
                variant="outlined"
                size="md"
                color={filter.tags.includes(tag.tag_id) ? 'primary' : 'neutral'}
                onClick={() =>
                  dispatchFilter({
                    type: 'SET_TAGS',
                    payload: filter.tags.includes(tag.tag_id)
                      ? filter.tags.filter((item) => item !== tag.tag_id)
                      : [...filter.tags, tag.tag_id],
                  })
                }
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setOpen(true);
                      setError(`Deleting this tag removes it from all properties.@${tag.tag_id}`);
                    }}
                  >
                    <CloseRounded />
                  </ChipDelete>
                }
              >
                {tag.name}
              </Chip>
            ))
          ) : (
            <div>no tags</div>
          )}
        </FormSection>

        <SectionTitle level="body-sm">saved</SectionTitle>
        <FormSection>
          <Stack direction="row" spacing={2}>
            {['yes', 'no'].map((option) => (
              <StyledButton
                key={option}
                variant="outlined"
                color="neutral"
                aria-pressed={filter.saved.includes(option)}
                onClick={() =>
                  dispatchFilter({
                    type: 'SET_SAVED',
                    payload: filter.saved.includes(option)
                      ? filter.saved.filter((item) => item !== option)
                      : [...filter.saved, option],
                  })
                }
              >
                {option}
              </StyledButton>
            ))}
          </Stack>
        </FormSection>

        <SectionTitle level="body-sm">area (sqft.)</SectionTitle>
        <FormSection>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MIN_AREA', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.min_area}
              defaultValue={initialFilter.min_area}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <Hyphen>-</Hyphen>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MAX_AREA', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.max_area}
              defaultValue={initialFilter.max_area}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              size="sm"
              label="Include properties with unknown area"
              checked={filter.include_null_area}
              onChange={() => dispatchFilter({ type: 'SET_INCLUDE_NULL_AREA', payload: !filter.include_null_area })}
            />
          </FormControl>
        </FormSection>

        <SectionTitle level="body-sm">price (£)</SectionTitle>
        <FormSection>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MIN_PRICE', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.min_price}
              defaultValue={initialFilter.min_price}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <Hyphen>-</Hyphen>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MAX_PRICE', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.max_price}
              defaultValue={initialFilter.max_price}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              size="sm"
              label="Include properties with unknown price"
              checked={filter.include_null_price}
              onChange={() => dispatchFilter({ type: 'SET_INCLUDE_NULL_PRICE', payload: !filter.include_null_price })}
            />
          </FormControl>
        </FormSection>

        <SectionTitle level="body-sm">psf (£/sqft.)</SectionTitle>
        <FormSection>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MIN_PSF', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.min_psf}
              defaultValue={initialFilter.min_psf}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <Hyphen>-</Hyphen>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MAX_PSF', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.max_psf}
              defaultValue={initialFilter.max_psf}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              size="sm"
              label="Include properties with unknown psf"
              checked={filter.include_null_psf}
              onChange={() => dispatchFilter({ type: 'SET_INCLUDE_NULL_PSF', payload: !filter.include_null_psf })}
            />
          </FormControl>
        </FormSection>

        <SectionTitle level="body-sm">percent leased (%)</SectionTitle>
        <FormSection>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MIN_PERCENT_LEASED', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.min_percent_leased}
              defaultValue={initialFilter.min_percent_leased}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <Hyphen>-</Hyphen>
          <FormControl>
            <DebouncedInput
              handleDebounce={(value) => dispatchFilter({ type: 'SET_MAX_PERCENT_LEASED', payload: parseInt(value) })}
              debounceTimeout={30}
              value={filter.max_percent_leased}
              defaultValue={initialFilter.max_percent_leased}
              slotProps={{ input: { component: NumericFormatAdapter } }}
              sx={{ width: '150px' }}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              size="sm"
              label="Include properties with unknown percent leased"
              checked={filter.include_null_percent_leased}
              onChange={() =>
                dispatchFilter({
                  type: 'SET_INCLUDE_NULL_PERCENT_LEASED',
                  payload: !filter.include_null_percent_leased,
                })
              }
            />
          </FormControl>
        </FormSection>

        <SectionTitle level="body-sm">last seen</SectionTitle>
        <FormSection>
          <Checkbox
            size="sm"
            label="all time"
            checked={includeAllTime}
            sx={{ width: '100%' }}
            onChange={() => {
              setIncludeAllTime(!includeAllTime);
              dispatchFilter({ type: 'SET_LAST_SEEN', payload: null });
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography level="body-sm">within</Typography>
            <Input
              type="number"
              defaultValue={3}
              sx={{ minWidth: '100px' }}
              slotProps={{
                input: {
                  min: 1,
                  max: 365,
                  step: 1,
                },
              }}
              disabled={includeAllTime}
              onChange={(event) => setNumber(Number(event.target.value))}
            />
            <Select
              defaultValue="days"
              disabled={includeAllTime}
              onChange={(_, newValue) => setUnit(newValue as 'days' | 'weeks' | 'months')}
            >
              <Option value="days">days</Option>
              <Option value="weeks">weeks</Option>
              <Option value="months">months</Option>
              <Option value="years">years</Option>
            </Select>
          </Box>
        </FormSection>

        <SectionTitle level="body-sm">listed</SectionTitle>
        <FormSection>
          <StyledButtonGroupContainer>
            <Stack spacing={3}>
              <StyledButton
                variant="outlined"
                color="neutral"
                aria-pressed={listedGrade[YesNoNa.YES]}
                onClick={() => handleListedGradeClick(YesNoNa.YES)}
              >
                {YesNoNa.YES}
              </StyledButton>
              {!listedButtonsDisabled && (
                <StyledButton
                  variant="outlined"
                  color="neutral"
                  aria-pressed={listedGrade[LG.I]}
                  onClick={() => handleListedGradeClick(LG.I)}
                >
                  {LG.I}
                </StyledButton>
              )}
            </Stack>

            <Stack spacing={3}>
              <StyledButton
                variant="outlined"
                color="neutral"
                aria-pressed={listedGrade[YesNoNa.NO]}
                onClick={() => handleListedGradeClick(YesNoNa.NO)}
              >
                {YesNoNa.NO}
              </StyledButton>
              {!listedButtonsDisabled && (
                <StyledButton
                  variant="outlined"
                  color="neutral"
                  aria-pressed={listedGrade[LG.II]}
                  onClick={() => handleListedGradeClick(LG.II)}
                >
                  {LG.II}
                </StyledButton>
              )}
            </Stack>

            <Stack spacing={3}>
              <StyledButton
                variant="outlined"
                color="neutral"
                aria-pressed={listedGrade[YesNoNa.NA]}
                onClick={() => handleListedGradeClick(YesNoNa.NA)}
              >
                {YesNoNa.NA}
              </StyledButton>
              {!listedButtonsDisabled && (
                <StyledButton
                  variant="outlined"
                  color="neutral"
                  aria-pressed={listedGrade[LG.II_STAR]}
                  onClick={() => handleListedGradeClick(LG.II_STAR)}
                >
                  {LG.II_STAR}
                </StyledButton>
              )}
            </Stack>
          </StyledButtonGroupContainer>
        </FormSection>

        <SectionTitle level="body-sm">article 4</SectionTitle>
        <FormSection>
          <Stack direction="row" spacing={2}>
            {Object.values(YesNoNa).map((option) => (
              <StyledButton
                key={option}
                variant="outlined"
                color="neutral"
                aria-pressed={filter.article4.includes(option)}
                onClick={() =>
                  dispatchFilter({
                    type: 'SET_ARTICLE4',
                    payload: filter.article4.includes(option)
                      ? filter.article4.filter((item) => item !== option)
                      : [...filter.article4, option],
                  })
                }
              >
                {option}
              </StyledButton>
            ))}
          </Stack>
        </FormSection>

        <SectionTitle level="body-sm">tenure</SectionTitle>
        <FormSection>
          <Stack direction="row" spacing={2}>
            {Object.values(Tenure).map((option) => (
              <StyledButton
                key={option}
                variant="outlined"
                color="neutral"
                aria-pressed={filter.tenure.includes(option)}
                onClick={() =>
                  dispatchFilter({
                    type: 'SET_TENURE',
                    payload: filter.tenure.includes(option)
                      ? filter.tenure.filter((item) => item !== option)
                      : [...filter.tenure, option],
                  })
                }
              >
                {option}
              </StyledButton>
            ))}
          </Stack>
        </FormSection>

        <SectionTitle level="body-sm">property types</SectionTitle>
        <FormSection>
          <Select
            multiple
            defaultValue={['all']}
            onChange={handlePropertyType}
            sx={{ width: '100%' }}
            slotProps={{ listbox: { sx: { height: '200px', width: '100%' } } }}
          >
            <Option value="all">All</Option>
            <Option value="office">Office</Option>
            <Option value="retail">Retail</Option>
          </Select>
        </FormSection>
      </form>
    </FilterContainer>
  );
};

export default FilterMenu;
