import { useContext } from "react";
import { PropertyDataContext } from "../contexts/PropertyData";
import { IPropertyDataContext } from "../types/prop-data.type";

const usePropData = (): IPropertyDataContext => {
    const context = useContext(PropertyDataContext);
    if (!context) {
        throw new Error('usePropData must be used within a PropertyDataProvider');
    }
    return context;
}
export default usePropData;