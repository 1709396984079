import { FC, ReactNode, createContext, useReducer } from 'react';
import { IPropertyData, IPropertyDataAction, IPropertyDataContext } from '../types/prop-data.type';

const initialPropertyData: IPropertyData = {
    pins: [],
    properties: new Map<string, any>(),
    loading: false,
    trackViewport: true,
    metrics: [],
    showMetrics: false,
    psfDist: null,
    selectedProperty: null,
    showPropertyModal: false,
    currentTags: new Map<string, any>(),
    viewOnMapId: null,
    metricFilter: {
        propertyTypes: [],
        beds: 1,
    },
    parsedProperties: new Map<string, any>(),
    parsedPins: [],
}

const propDataReducer = (state: IPropertyData, action: IPropertyDataAction): IPropertyData => {
    switch (action.type) {
        case 'SET_PINS':
            return { ...state, pins: action.payload };
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'CLEAR_PROPERTIES':
            return { ...state, properties: new Map<string, any>() };
        case 'ADD_PROPERTIES':
            const newProperties = action.payload;
            const newPropertyMap = new Map<string, any>(state.properties);
            newProperties.forEach((property: any) => {
                newPropertyMap.set(property.property_id, property);
            });
            return { ...state, properties: newPropertyMap };
        case 'ADD_PARSED_PROPERTIES':
            const newParsedProperties = action.payload;
            const newParsedPropertyMap = new Map<string, any>(state.parsedProperties);
            newParsedProperties.forEach((property: any) => {
                newParsedPropertyMap.set(property.property_id, property);
            });
            return { ...state, parsedProperties: newParsedPropertyMap };
        case 'ADD_PARSED_PINS':
            return { ...state, parsedPins: [...state.parsedPins, ...action.payload] };

        case 'SET_TRACK_VIEWPORT':
            return { ...state, trackViewport: action.payload };
        case 'SET_METRICS':
            return { ...state, metrics: action.payload };
        case 'SET_SHOW_METRICS':
            return { ...state, showMetrics: action.payload };
        case 'SET_PSF_DIST':
            return { ...state, psfDist: action.payload };
        case 'SET_CURRENT_TAGS':
            let newTags = new Map<string, any>();
            if (Array.isArray(action.payload)) {
                action.payload.forEach((tag) => {
                    newTags.set(tag.tag_id, tag);
                });
            } else {
                newTags = action.payload;
            }
            return { ...state, currentTags: newTags };
        
        case 'ADD_TAG':
            const newTag = action.payload;
            const newTagMap = new Map<string, any>(state.currentTags);
            newTagMap.set(newTag.tag_id, newTag);
            return { ...state, currentTags: newTagMap };
        case 'REMOVE_TAG':
            const { propIds, tagId } = action.payload;
            const tagMap = new Map<string, any>(state.currentTags);
            tagMap.delete(tagId);
            const newPropMap = new Map<string, any>(state.properties);
            propIds.forEach((propId: string) => {
                const property = newPropMap.get(propId);
                if (property) {
                    property.tags = property.tags.filter((tag: string) => tag !== tagId);
                    newPropMap.set(propId, property);
                }
            });
            return { ...state, currentTags: tagMap, properties: newPropMap };
        
        case 'SET_SELECTED_PROPERTY':
            return { ...state, selectedProperty: action.payload };
        case 'UPDATE_SELECTED_PROPERTY':
            if (!state.selectedProperty) return state;
            return { ...state, selectedProperty: { ...state.selectedProperty, ...action.payload } };

        case 'SET_VIEW_ON_MAP_ID':
            return { ...state, viewOnMapId: action.payload };

        case 'SET_METRIC_FILTER':
            return { ...state, metricFilter: action.payload };
        case 'RESET_METRIC_FILTER':
            return { ...state, metricFilter: initialPropertyData.metricFilter };
        
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

const PropertyDataContext = createContext<IPropertyDataContext>({} as IPropertyDataContext);

const PropertyDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(propDataReducer, initialPropertyData);

    return (
        <PropertyDataContext.Provider value={{ state, dispatch }}>
            {children}
        </PropertyDataContext.Provider>
    );
}

export default PropertyDataProvider;
export { propDataReducer, initialPropertyData, PropertyDataContext, PropertyDataProvider };