import { FC, memo, useRef, useCallback, useEffect, useState, CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import IconButton from '@mui/joy/IconButton';
import { GoogleMap, useJsApiLoader, Libraries } from '@react-google-maps/api';
import { CustomRenderer } from './Renderer';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { getPins } from '../api/properties.api';
import useFilter from '../hooks/useFilter';
import usePropData from '../hooks/usePropData';
import LayersRounded from '@mui/icons-material/LayersRounded'; // layers
import AnalyticsRounded from '@mui/icons-material/AnalyticsRounded'; // rentals
import DomainDisabledRouned from '@mui/icons-material/DomainDisabledRounded'; // a4
import SynagogueOutlined from '@mui/icons-material/SynagogueOutlined'; // nhle
import LandscapeRounded from '@mui/icons-material/LandscapeRounded'; // terrain
import GpsNotFixedRounded from '@mui/icons-material/GpsNotFixedRounded'; // track on
import LocationDisabledRounded from '@mui/icons-material/LocationDisabledRounded'; // track off
import LocationOnRounded from '@mui/icons-material/LocationOnRounded'; // visibility on
import useLayers from '../hooks/useLayers';
import { BASE_ENDPOINT } from '../api/config';
import { urlJoin } from '../api/utils';
import Tooltip from '@mui/joy/Tooltip';
import { getProperty } from '../api/properties.api';

const googleMapContainerStyle = {
  width: '100%',
  height: '100%',
  '& .gmStyle iframe + div': {
    border: 'none !important',
  },
};
const defaults = {
  zoom: 11,
  center: {
    lat: 51.507,
    lng: -0.127,
  },
  options: {
    fullscreenControl: false,
    mapTypeControl: false,
    mapTypeId: 'roadmap',
    styles: [{ featureType: 'poi', stylers: [{ visibility: 'off' }] }],
    rotateControl: true,
    rotateControlOptions: {
      position: 3, // right center
    },
  },
};

const popupStyles = {
  position: 'absolute',
  right: '45px',
  height: '40px',
  backgroundColor: 'white',
  width: '180px',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
  zIndex: 51,
  padding: '5px',
} as CSSProperties;

const gmapSearchStyles = {
  width: '300px',
  height: '35px',
  borderRadius: '15px',
  border: '1px solid #fff',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  fontSize: '14px',
  padding: '8px 15px',
} as CSSProperties;

const layerControls = [
  { icon: <AnalyticsRounded />, layerId: 'msoa_4326', title: 'Rental' },
  { icon: <DomainDisabledRouned />, layerId: 'a4_4326', title: 'Article 4' },
  { icon: <SynagogueOutlined />, layerId: 'nhle_4326', title: 'Historic England' },
  // { icon: <LandscapeRounded />, layerId: 'terrain', title: 'Terrain' },
];

const distanceToDegrees = (distance: number) => {
  const earthRadius = 6371000; // in meters
  const delta = (distance / earthRadius) * (180 / Math.PI);
  return delta;
};

//AIzaSyAfTwgKakiL0kTBPEW6cbGq8_O8HAp8Wvg
const libraries: Libraries = ['places'];

const GMap: FC = () => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const clustererRef = useRef<MarkerClusterer | null>(null);
  const markersRef = useRef<Map<string, google.maps.Marker> | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const highlightRef = useRef<google.maps.Marker | null>(null);

  const { state: filter, dispatch: dispatchFilter } = useFilter();
  const { state: data, dispatch: dispatchPropData } = usePropData();
  const { toggleLayer } = useLayers(mapRef);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAfTwgKakiL0kTBPEW6cbGq8_O8HAp8Wvg',
    libraries: libraries,
  });
  const [viewingOnMap, setViewingOnMap] = useState(false);

  const [containers, setContainers] = useState<{
    layers: HTMLDivElement | null;
    track: HTMLDivElement | null;
    visibility: HTMLDivElement | null;
    search: HTMLDivElement | null;
  }>({ layers: null, track: null, visibility: null, search: null });

  const [expand, setExpand] = useState(false);

  const fetchPinsAndUpdate = useCallback(async () => {
    // warning: properties must be cleared after pins are set
    dispatchPropData({ type: 'SET_LOADING', payload: true });
    const pins = await getPins(filter);
    if (pins) dispatchPropData({ type: 'SET_PINS', payload: pins });
    dispatchPropData({ type: 'CLEAR_PROPERTIES' });
    dispatchPropData({ type: 'SET_LOADING', payload: false });
  }, [filter.viewport, dispatchPropData]);

  const setTilesDateRange = useCallback(async (startDate: string, endDate: string) => {
    try {
      await fetch(urlJoin(BASE_ENDPOINT, 'tiles', 'set-date-range'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ startDate, endDate }),
      });
      return true;
    } catch (error) {
      console.error('Failed to set tiles date range');
      return false;
    }
  }, []);

  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;

    if (mapContainerRef.current) {
      const layersDiv = document.createElement('div');
      layersDiv.style.position = 'absolute';
      layersDiv.style.right = '10px';
      layersDiv.style.bottom = '212px';
      layersDiv.style.width = '40px';
      layersDiv.style.height = '40px';
      layersDiv.style.borderRadius = '15%';
      layersDiv.style.display = 'flex';
      layersDiv.style.justifyContent = 'center';
      layersDiv.style.alignItems = 'center';
      layersDiv.style.backgroundColor = 'white';
      // layersDiv.style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.25)';
      mapContainerRef.current.appendChild(layersDiv);

      const trackDiv = document.createElement('div');
      trackDiv.style.position = 'absolute';
      trackDiv.style.right = '10px';
      trackDiv.style.top = '20px';
      trackDiv.style.width = '40px';
      trackDiv.style.height = '40px';
      trackDiv.style.borderRadius = '15%';
      trackDiv.style.display = 'flex';
      trackDiv.style.justifyContent = 'center';
      trackDiv.style.alignItems = 'center';
      trackDiv.style.backgroundColor = 'white';
      mapContainerRef.current.appendChild(trackDiv);

      const visibilityDiv = document.createElement('div');
      visibilityDiv.style.position = 'absolute';
      visibilityDiv.style.right = '10px';
      visibilityDiv.style.top = '70px';
      visibilityDiv.style.width = '40px';
      visibilityDiv.style.height = '40px';
      visibilityDiv.style.borderRadius = '15%';
      visibilityDiv.style.display = 'flex';
      visibilityDiv.style.justifyContent = 'center';
      visibilityDiv.style.alignItems = 'center';
      visibilityDiv.style.backgroundColor = 'white';
      mapContainerRef.current.appendChild(visibilityDiv);

      const searchDiv = document.createElement('div');
      searchDiv.style.position = 'absolute';
      searchDiv.style.left = '20px';
      searchDiv.style.top = '20px';
      searchDiv.style.display = 'flex';
      searchDiv.style.justifyContent = 'center';
      searchDiv.style.alignItems = 'center';
      mapContainerRef.current.appendChild(searchDiv);

      setContainers({ layers: layersDiv, track: trackDiv, visibility: visibilityDiv, search: searchDiv });
    }
  }, []);

  const onUnmount = useCallback(() => {
    if (clustererRef.current) {
      clustererRef.current.clearMarkers();
    }
    mapRef.current = null;
    clustererRef.current = null;
  }, []);

  const onBoundsChanged = useCallback(() => {
    // console.log('bounds changed');
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (data.trackViewport && !viewingOnMap) {
      timeoutRef.current = setTimeout(() => {
        if (mapRef.current) {
          const ne = mapRef.current.getBounds()?.getNorthEast().toJSON();
          const sw = mapRef.current.getBounds()?.getSouthWest().toJSON();
          dispatchFilter({ type: 'SET_VIEWPORT', payload: { ne, sw } });
        }
      }, 500);
    } else if (viewingOnMap) {
      setViewingOnMap(false);
    }
  }, [dispatchFilter, data.trackViewport, viewingOnMap]);

  const onMouseOver = useCallback((marker: google.maps.Marker) => {
    marker.setIcon({
      url: 'highlight-marker.png',
      scaledSize: new google.maps.Size(35, 35),
    });
  }, []);

  const onMouseOut = useCallback((marker: google.maps.Marker, parsed: boolean) => {
    marker.setIcon({
      url: parsed ? 'parsed-marker.png' : 'marker.png',
      scaledSize: new google.maps.Size(25, 25),
    });
  }, []);

  const toggleMarkerVisibility = useCallback(() => {
    if (markersRef.current) {
      const newVisibility = !markersRef.current.values().next().value.getVisible();

      markersRef.current.forEach((marker, propId) => {
        marker.setVisible(newVisibility);
      });

      if (clustererRef.current) {
        clustererRef.current.clearMarkers();
        if (newVisibility) {
          clustererRef.current.addMarkers(Array.from(markersRef.current.values()));
        }
      }
    }
  }, []);

  // set tiles date range on mount
  useEffect(() => {
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    setTilesDateRange(startDate.toISOString(), new Date().toISOString());
  }, [setTilesDateRange]);

  // refetch pins on viewport change, when not viewing on map
  useEffect(() => {
    if (filter.viewport) {
      fetchPinsAndUpdate();
    }
  }, [filter.viewport, fetchPinsAndUpdate]);

  // view on map
  useEffect(() => {
    if (mapRef.current && data.viewOnMapId) {
      if (markersRef.current && markersRef.current.has(data.viewOnMapId)) {
        setViewingOnMap(true);
        if (highlightRef.current) {
          highlightRef.current.setIcon({
            url: '/marker.png',
            scaledSize: new google.maps.Size(25, 25),
          });
          highlightRef.current.setZIndex(null);
        }

        const marker = markersRef.current.get(data.viewOnMapId);
        if (marker) {
          const { lat, lng } = marker?.getPosition()?.toJSON() || { lat: 0, lng: 0 };
          const deltaDegrees = distanceToDegrees(500);
          const bounds = new google.maps.LatLngBounds();
          bounds.extend(new google.maps.LatLng(lat + deltaDegrees, lng + deltaDegrees));
          bounds.extend(new google.maps.LatLng(lat - deltaDegrees, lng - deltaDegrees));
          mapRef.current.fitBounds(bounds);

          marker.setIcon({
            url: '/highlight-marker.png',
            scaledSize: new google.maps.Size(35, 35),
          });
          marker.setZIndex(Number(google.maps.Marker.MAX_ZINDEX) + 1);

          highlightRef.current = marker;
        }
      }
    }
  }, [data.viewOnMapId]);

  // google places search
  useEffect(() => {
    if (searchRef.current && markersRef.current && mapRef.current) {
      setViewingOnMap(true);

      const londonBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(51.390135254061704, -0.31956741943361067),
        new google.maps.LatLng(51.59000977407033, 0.14185836181638933)
      );

      const searchBox = new google.maps.places.SearchBox(searchRef.current);
      searchBox.setBounds(londonBounds);
      searchBox.addListener('places_changed', () => {
        if (highlightRef.current) {
          highlightRef.current.setMap(null);
          highlightRef.current = null;
        }

        const places = searchBox.getPlaces();

        if (places && places.length === 1) {
          const bounds = new google.maps.LatLngBounds();

          const place = places[0];
          if (!place || !place.geometry || !markersRef.current || !mapRef.current) return;

          const marker = new google.maps.Marker({
            map: mapRef.current,
            position: place.geometry.location,
            title: place.name,
            icon: {
              url: '/highlight-marker.png',
              scaledSize: new google.maps.Size(25, 25),
            },
          });

          highlightRef.current = marker;

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else if (place.geometry.location) {
            bounds.extend(place.geometry.location);
          }

          mapRef.current.fitBounds(bounds);
        } else {
          console.error('more than one place change');
        }
      });
    }
  }, [searchRef.current, markersRef.current, mapRef.current]);

  // generate markers
  useEffect(() => {
    if (clustererRef.current) {
      clustererRef.current.clearMarkers();
    }
    if (mapRef.current && data.pins !== null && data.pins.length > 0) {
      
      markersRef.current = new Map<string, google.maps.Marker>();

      const onMarkerClick = async (propId: string) => {
        const findProperty = (map: Map<string, any>) => map.get(propId);
        const property =
          findProperty(data.parsedProperties) || findProperty(data.properties) || (await getProperty(propId));

        if (property) {
          if (!(data.properties.has(propId) || data.parsedProperties.has(propId))) {
            dispatchPropData({ type: 'ADD_PROPERTIES', payload: [property] });
          }
          dispatchPropData({ type: 'SET_SELECTED_PROPERTY', payload: property });
        }
      };

      const addMarker = (pin: any) => {
        const { property_id, geography, scraped_address, parsed } = pin;
        if (geography) {
          const [lng, lat] = geography.coordinates;
          const marker = new google.maps.Marker({
            position: { lat, lng },
            title: scraped_address,
            icon: {
              url: parsed ? '/parsed-marker.png' : '/marker.png',
              scaledSize: new google.maps.Size(25, 25),
            },
          });

          marker.addListener('click', () => onMarkerClick(property_id));
          marker.addListener('mouseover', () => onMouseOver(marker));
          marker.addListener('mouseout', () => onMouseOut(marker, parsed));

          markersRef.current?.set(property_id, marker);
        }
      };

      data.pins.forEach(addMarker);
      data.parsedPins.forEach(addMarker);

      const customRenderer = new CustomRenderer();
      clustererRef.current = new MarkerClusterer({
        map: mapRef.current,
        markers: Array.from(markersRef.current.values()),
        renderer: customRenderer,
        algorithm: new SuperClusterAlgorithm({ maxZoom: 14, radius: 100, minPoints: 10 }),
      });
    }
  }, [data.pins, data.parsedPins, mapRef.current]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div ref={mapContainerRef} style={{ position: 'relative', width: '100%', height: '100%' }}>
      <GoogleMap
        mapContainerStyle={googleMapContainerStyle}
        center={defaults.center}
        zoom={defaults.zoom}
        options={defaults.options}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onBoundsChanged={onBoundsChanged}
      />
      {/* gmap search */}
      {containers.search &&
        createPortal(
          <input ref={searchRef} type="text" placeholder="Google Maps Search" style={gmapSearchStyles} />,
          containers.search
        )}

      {/* layers control */}
      {containers.layers &&
        createPortal(
          <>
            {/* toggle */}
            <Tooltip title="layers" placement="bottom">
              <IconButton onClick={() => setExpand((prev) => !prev)}>
                <LayersRounded />
              </IconButton>
            </Tooltip>

            {/* layers */}
            {expand && (
              <div style={popupStyles}>
                {layerControls.map((control, index) => (
                  <Tooltip key={index} title={control.title} placement="bottom">
                    <IconButton key={index} onClick={() => toggleLayer(control.layerId)}>
                      {control.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </div>
            )}
          </>,
          containers.layers
        )}

      {/* track control */}
      {containers.track &&
        createPortal(
          <Tooltip title={data.trackViewport ? 'turn off tracking' : 'turn on tracking'} placement="bottom">
            <IconButton onClick={() => dispatchPropData({ type: 'SET_TRACK_VIEWPORT', payload: !data.trackViewport })}>
              {data.trackViewport ? <GpsNotFixedRounded /> : <LocationDisabledRounded />}
            </IconButton>
          </Tooltip>,
          containers.track
        )}

      {/* marker visibility */}
      {containers.visibility &&
        createPortal(
          <Tooltip title="toggle markers" placement="bottom">
            <IconButton onClick={toggleMarkerVisibility}>
              <LocationOnRounded />
            </IconButton>
          </Tooltip>,
          containers.visibility
        )}
    </div>
  );
};

export default memo(GMap);
