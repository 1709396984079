import { FC, useState } from 'react';
import Button from '@mui/joy/Button';
import SvgIcon from '@mui/joy/SvgIcon';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { styled } from '@mui/joy';

const FileUploadIcon = () => (
    <SvgIcon>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
        </svg>
    </SvgIcon>
)

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

interface FileUploadButtonProps {
    title?: string;
    allowedExtensions?: string[];
    onFileSelect?: (file: File | null) => void;
    width?: string;
    disabled?: boolean;
}

const FileUploadButton: FC<FileUploadButtonProps> = ({
    title,
    allowedExtensions = ['.pdf', '.xlsx', '.csv', '.json', '.jl'],
    onFileSelect,
    width='auto',
    disabled=false
}) => {

    const [fileName, setFileName] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setError(null);

        if (file) {
            const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
            if (allowedExtensions.includes(fileExtension)) {
                setFileName(file.name);
                onFileSelect && onFileSelect(file);
            } else {
                setFileName(null);
                setError(`Invalid file type. Allowed types: ${allowedExtensions.join(', ')}`);
                onFileSelect && onFileSelect(null);
            }
        } else {
            setFileName(null);
            onFileSelect && onFileSelect(null);
        }
    };

return (
    <Box>
        <Button
            component="label"
            role={undefined}
            disabled={disabled}
            variant="outlined"
            color={error ? 'danger' : 'neutral'}
            startDecorator={<FileUploadIcon />}
            sx={{
                width: width,
                alignItems: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {fileName || title || 'Upload a File'}
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
        </Button>
        {error && (
            <Typography level="body-xs" color="danger" sx={{ mt: 1 }}>
                {error}
            </Typography>
        )}
    </Box>
);
}

export default FileUploadButton;