import { FC, useState, useCallback, useMemo } from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import Skeleton from '@mui/joy/Skeleton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Sheet from '@mui/joy/Sheet';
import Table, { TableProps } from '@mui/joy/Table';

const MetricTable: FC<{ title: string, rows: any }> = ({ title, rows }) => (
    <Box sx={{ mt: 2 }}>
        <Typography level="h3" fontSize="lg" mb='8px'>{title}</Typography>
        <Table borderAxis='xBetween' size='sm'>
            <thead>
                <tr>
                    <th>metric</th>
                    <th>rent</th>
                    <th>sale</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row: any) => {
                    const { metricName, rent, sale } = row;
                    return (
                        <tr key={metricName}>
                            <th scope='row'>{metricName}</th>
                            <td>{rent}</td>
                            <td>{sale}</td>
                        </tr>
                    );})}
            </tbody>
        </Table>
    </Box>
)

const listedMetricFields = {
    'price range': ['minListedPrice', 'maxListedPrice'],
    'price': 'listedPriceDeciles',
    'avg area': 'avgListedArea',
    'psf range': ['minListedPSF', 'maxListedPSF'],
    'psf': 'listedPSFDeciles',
    'data points': 'listedAreaN'
}

const delistedMetricFields = {
    'price range': ['minDelistedPrice', 'maxDelistedPrice'],
    'price': 'delistedPriceDeciles',
    'avg area': 'avgDelistedArea',
    'psf range': ['minDelistedPSF', 'maxDelistedPSF'],
    'psf': 'delistedPSFDeciles',
    'data points': 'delistedAreaN'
}


const RentMetricCard: FC<{ metric: any, percentile: number }> = ({ metric, percentile }) => {
    const { code, name, rentMetric, saleMetric } = metric;
    const [expanded, setExpanded] = useState(false);
    
    const psfIndex = useMemo(() => Math.floor(percentile / 10) - 1, [percentile]);

    const createData = useCallback((
        metricName: string,
        metricField: string | string[],
        rentMetric: any,
        saleMetric: any
    ) => {
        if (Array.isArray(metricField)) {
            return { 
                metricName, 
                rent: `${rentMetric[metricField[0]]?.toLocaleString() ?? 'NA'} - ${rentMetric[metricField[1]]?.toLocaleString() ?? 'NA'}`, 
                sale: `${saleMetric[metricField[0]]?.toLocaleString() ?? 'NA'} - ${saleMetric[metricField[1]]?.toLocaleString() ?? 'NA'}` 
            }
        }
        let rent = rentMetric[metricField];
        let sale = saleMetric[metricField];

        if (typeof rent === 'number') { 
            rent = Math.floor(rent);
            sale = Math.floor(sale);
            return { metricName, rent, sale }
        } else if (Array.isArray(rent)) {
            return { metricName, rent: rent[psfIndex] ?? 'NA', sale: sale[psfIndex] ?? 'NA' }
        } else {
            // console.error('invalid metric field', metricField);
            return { metricName, rent: 'N/A', sale: 'N/A' }
        }
    }, [psfIndex]);

    const handleExpand = () => setExpanded(!expanded);

    const listData = Object.entries(listedMetricFields).map(([metricName, metricField]) => createData(metricName, metricField, rentMetric, saleMetric));
    const delistedData = Object.entries(delistedMetricFields).map(([metricName, metricField]) => createData(metricName, metricField, rentMetric, saleMetric));

    return (
        <Card variant="outlined" sx={{ width: '95%', mb: 2, mt: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                        {`${name} (${code})`}
                    </Typography>
                    <IconButton
                        aria-label="expand"
                        size="sm"
                        onClick={handleExpand}
                    >
                        {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Box>
                <Typography level="body-sm">
                    {`[rental] listed psf: ${rentMetric['listedPSFDeciles']? rentMetric['listedPSFDeciles'][psfIndex] : 'NA'} | delisted psf: ${rentMetric['delistedPSFDeciles'] ? rentMetric['delistedPSFDeciles'][psfIndex] : 'NA'}`}
                </Typography>
                <Typography level="body-sm">
                    {`[sale] listed psf: ${saleMetric['listedPSFDeciles']? saleMetric['listedPSFDeciles'][psfIndex] : 'NA'} | delisted psf: ${saleMetric['delistedPSFDeciles'] ? saleMetric['delistedPSFDeciles'][psfIndex] : 'NA'}`}
                </Typography>
                {expanded && (
                    <>
                        <MetricTable title='listed' rows={listData} />
                        <MetricTable title='delisted' rows={delistedData} />
                    </>
                )}
            </CardContent>
        </Card>

    );
}

export default RentMetricCard;