import { FC, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import Sheet from '@mui/joy/Sheet';
import { styled } from '@mui/joy/styles';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AddRounded from '@mui/icons-material/AddRounded';
import { Outlet } from 'react-router-dom';
import Box from '@mui/joy/Box';
import { getTags } from '../api/tags.api';
import usePropData from '../hooks/usePropData';

//TODO: history
const APP_BAR_WIDTH = 100;

const LayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100%',
  maxWidth: '100%',
}));

const AppBar = styled(Sheet)(({ theme }) => ({
  width: APP_BAR_WIDTH,
  height: '100%',
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: 10,
  p: '1 0',
  zIndex: 1200,
  borderRight: `1px solid ${theme.colorSchemes.light.palette.divider}`,
}));

const StyledTabList = styled(TabList)(({ theme }) => ({
  p: 0.5,
  gap: theme.spacing(3),
  borderRadius: 'xl',
  bgcolor: 'background.level1',
  flexDirection: 'column',
  [`& .${tabClasses.root}[aria-selected='true']`]: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    bgcolor: theme.colorSchemes.light.palette.primary[500],
  },
}));

const MainContentContainer = styled(Box)(({ theme }) => ({
  margin: '0 32px',
  width: '100%',
  maxWidth: '100%',
}));

const LogoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '70%',
  height: 'auto',
  padding: theme.spacing(0.5),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const tabInfo = [
  {
    value: 0,
    icon: <HomeRoundedIcon />,
  },
  {
    value: 1,
    icon: <AddRounded />,
  },
];

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const { dispatch } = usePropData();

  useEffect(() => {
    const fetchAndSetTags = async () => {
      const tags = await getTags();
      dispatch({ type: 'SET_CURRENT_TAGS', payload: tags });
    };
    fetchAndSetTags();
  }, []);

  const getTabIndex = (pathname: string) => {
    switch (pathname) {
      case '/':
        return 0;
      case '/parse':
        return 1;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setCurrentTab(getTabIndex(location.pathname));
  }, [location.pathname]);

  const handleTabChange = (event: React.SyntheticEvent | null, newValue: number | string | null) => {
    setCurrentTab(newValue as number);
    switch (newValue) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/parse');
        break;
    }
  };

  return (
    <LayoutContainer>
      <AppBar>
        <LogoBox>
          <img src="/login_logo_black.svg" alt="logo" style={{ width: '100%' }} />
        </LogoBox>
        <Tabs
          orientation="vertical"
          value={currentTab}
          onChange={handleTabChange}
          sx={{ bgcolor: 'transparent', height: '100%' }}
        >
          <StyledTabList disableUnderline>
            {tabInfo.map((tab) => (
              <Tab
                disableIndicator
                value={tab.value}
                key={tab.value}
                aria-selected={currentTab === tab.value}
                sx={{
                  borderRadius: 'xl',
                  height: '50px',
                }}
              >
                {tab.icon}
              </Tab>
            ))}
          </StyledTabList>
        </Tabs>
      </AppBar>

      <MainContentContainer component="main">
        <Outlet />
      </MainContentContainer>
    </LayoutContainer>
  );
};

export default Layout;
