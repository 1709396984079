import { Dispatch } from 'react';

export enum ListedGrade {
    I = 'I',
    II = 'II',
    II_STAR = 'II*',
}

export enum YesNoNa {
    YES = 'yes',
    NO = 'no',
    NA = 'na',
}

export enum Tenure {
    FREEHOLD = 'freehold',
    LEASEHOLD = 'leasehold',
    VIRTUAL_FREEHOLD = 'virtual freehold',
}

export interface IFilterContext {
    state: IFilter;
    dispatch: Dispatch<IFilterAction>;
}

export interface IFilter {
    min_area: number;
    max_area: number;
    include_null_area: boolean;

    min_price: number;
    max_price: number;
    include_null_price: boolean;

    min_psf: number;
    max_psf: number;
    include_null_psf: boolean;

    min_percent_leased: number;
    max_percent_leased: number;
    include_null_percent_leased: boolean;

    listedGrade: string[];
    listed: string[];

    article4: string[];
    tenure: string[];
    propertyTypes: string[];

    tags: string[];
    saved: string[];
    last_seen: number | null;

    viewport: any;
    
    sortBy: string;
    sortOrder: string;

    startDate: string;
    endDate: string;
}

export interface IFilterAction {
    type: string;
    payload: any;
}