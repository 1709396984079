import { BASE_ENDPOINT } from './config'
import { urlJoin } from './utils'

export const getMsoaMetrics = async (
    msoaCodes: string[], 
    startDate: string, 
    endDate: string,
    propertyTypes: string[],
    beds: number
) => {
    const response = await fetch(
        urlJoin(BASE_ENDPOINT, 'metrics', 'msoa'),
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                msoaCode: msoaCodes,
                startDate,
                endDate,
                computeMetrics: true,
                propertyTypes,
                beds,
            })
        }
    );
    try {
        return await response.json();
    } catch (error) {
        console.error('Error fetching metrics', error);
        return null;
    }
}

export const getMsoaPsfDist = async (msoaCodes: string[]) => {
    const response = await fetch(
        urlJoin(BASE_ENDPOINT, 'metrics', 'psf-dist') + `?msoa-codes=${msoaCodes.join(',')}`,
    );
    try {
        return await response.json();
    } catch (error) {
        console.error('Error fetching psf distribution', error);
        return null;
    }
}

export const getMsoaData = async (
    msoaCodes: string[],
    startDate: string,
    endDate: string,
    propertyTypes: string[],
    beds: number
) => {
    const response = await fetch(
        urlJoin(BASE_ENDPOINT, 'metrics', 'msoa'),
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                msoaCode: msoaCodes,
                startDate,
                endDate,
                computeMetrics: false,
                propertyTypes,
                beds,
            })
        }
    );
    try {
        return await response.json();
    } catch (error) {
        console.error('Error fetching data', error);
        return null;
    }
}