import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy';
import { ThemeProvider } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import { joyTheme, muiTheme } from './theme';
import FilterProvider from './contexts/Filter';
import PropertyDataProvider from './contexts/PropertyData';
import Layout from './pages/Layout';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage'
import ParsePage from './pages/ParsePage';
import { isAuthenticated } from './auth/user-auth';
import './index.css';
// import App from './App';


interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  if (isAuthenticated()) {
    return <>{children}</>;
  }
  return <Navigate to="/login" replace />;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ProtectedRoute><HomePage /></ProtectedRoute>
      },
      {
        path: 'parse',
        element: <ProtectedRoute><ParsePage /></ProtectedRoute>
      }
    ],
  },
  {
    path: '/login',
    element: <LoginPage />
  }
])

const root = document.getElementById('root');
if (!root) throw new Error('root element not found');

ReactDOM.createRoot(root).render(
  // <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
    <CssVarsProvider theme={joyTheme}>
      <PropertyDataProvider>
        <FilterProvider>
          <CssBaseline />
          <RouterProvider router={router} />
        </FilterProvider>
      </PropertyDataProvider>
    </CssVarsProvider>
    </ThemeProvider>
  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
