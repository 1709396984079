import { FC, useState, useCallback, useEffect, useMemo } from 'react';
import Box from '@mui/joy/Box';
import { styled } from '@mui/joy/styles';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import usePropData from '../hooks/usePropData';
import useFilter from '../hooks/useFilter';
import { getProperties, getProperty, updateProperty } from '../api/properties.api';
import PropertyCard from './PropertyCard';
import Typography from '@mui/joy/Typography';
import CircularProgress from '@mui/joy/CircularProgress';

const ListContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  border: `1px solid ${theme.vars.palette.neutral.outlinedBorder}`, //
  borderRadius: '1%',
  padding: theme.spacing(1),
}));
const BATCH_SIZE = 20;
const CARD_HEIGHT = 360;
const CARD_PADDING = 10;

const getPropertyId = (pin: any) => pin.property_id;

const PropertyList: FC = () => {
  const { state: data, dispatch } = usePropData();
  const { state: filter } = useFilter();

  const { sortBy, sortOrder } = filter;
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);

  const sortedPinIds = useMemo(() => {
    if (!(data.pins && data.parsedPins)) return [];
    const parsedPinIds = data.parsedPins?.map(getPropertyId) ?? [];
    const pinIds = data.pins?.map(getPropertyId) ?? [];
    if (!sortBy || data.pins.length == 0) return parsedPinIds.concat(pinIds);

    const compare = (a: any, b: any): number => {
      const aHasAttr = a.hasOwnProperty(sortBy);
      const bHasAttr = b.hasOwnProperty(sortBy);
      if (!aHasAttr || !bHasAttr) {
        return aHasAttr ? -1 : bHasAttr ? 1 : 0;
      }
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return sortOrder === 'ASC' ? aValue - bValue : bValue - aValue;
      } else if (aValue === null || bValue === null) {
        return aValue === null ? 1 : bValue === null ? -1 : 0;
      } else {
        throw new Error(`Invalid sort key: ${sortBy}, ${aValue}, ${bValue}`);
      }
    };

    return data.parsedPins
      .slice()
      .sort(compare)
      .map(getPropertyId)
      .concat(data.pins.slice().sort(compare).map(getPropertyId));
  }, [data.parsedPins, data.pins, sortBy, sortOrder]);

  const loadMoreProperties = useCallback(
    async (startIndex: number, stopIndex: number) => {
      if (!hasNextPage || isLoadingProperties || !sortedPinIds || sortedPinIds.length === 0) return;

      setIsLoadingProperties(true);

      const idsToFetch = sortedPinIds.slice(startIndex, stopIndex);
      const newProperties = await getProperties(idsToFetch, sortBy, sortOrder);
      dispatch({ type: 'ADD_PROPERTIES', payload: newProperties });

      if (newProperties.length < idsToFetch.length) {
        setHasNextPage(false);
      }
      setIsLoadingProperties(false);
    },
    [sortedPinIds, hasNextPage, isLoadingProperties, dispatch, sortBy, sortOrder, data.parsedPins.length]
  );

  useEffect(() => {
    if (sortedPinIds.length > 0 && data.properties.size === 0) {
      loadMoreProperties(data.parsedProperties.size, data.parsedProperties.size + BATCH_SIZE);
    }
  }, [sortedPinIds, data.properties.size, data.parsedProperties.size, loadMoreProperties]);

  const handleClick = useCallback(
    async (id: string) => {
      const findProperty = (map: Map<string, any>) => map.get(id);

      const property = findProperty(data.properties) || findProperty(data.parsedProperties) || (await getProperty(id));
      console.log('property', property);
      if (property) {
        if (!data.properties.has(id) && !data.parsedProperties.has(id)) {
          dispatch({ type: 'ADD_PROPERTIES', payload: [property] });
        }
        dispatch({ type: 'SET_SELECTED_PROPERTY', payload: property });
      }
    },
    [data.properties, data.parsedProperties]
  );

  const handleViewOnMap = useCallback(
    (viewOnMapId: string) => {
      dispatch({ type: 'SET_VIEW_ON_MAP_ID', payload: viewOnMapId });
    },
    [data.viewOnMapId]
  );

  const handleBookmark = useCallback(
    async (id: string) => {
      if (data.properties && data.properties.has(id)) {
        const property = data.properties.get(id);
        const newProperty = { ...property, saved: !property.saved };
        dispatch({ type: 'ADD_PROPERTIES', payload: [newProperty] });
        await updateProperty(id, { saved: newProperty.saved });
      }
    },
    [data.properties]
  );

  const Row: FC<ListChildComponentProps> = ({ index, style }) => {
    const id = sortedPinIds[index];
    const property = data.parsedProperties.get(id) || data.properties.get(id);

    return (
      <Box
        style={{
          ...style,
          height: CARD_HEIGHT - CARD_PADDING, // Adjust height to account for padding
          padding: `${CARD_PADDING / 2}px ${CARD_PADDING}px`, // Add padding around the card
        }}
      >
        {property ? (
          <PropertyCard
            property={property}
            handleClick={handleClick}
            handleViewOnMap={handleViewOnMap}
            handleBookmark={handleBookmark}
            savable={!data.parsedProperties.has(id)}
          />
        ) : (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Loading...</Box>
        )}
      </Box>
    );
  };

  const content = () => {
    if (data.loading || !data.pins || data.pins.length === 0)
      return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 5 }}>
          <Typography level='body-lg'>Loading Properties</Typography>
          <CircularProgress size='lg' />
        </Box>
      );
 
    return (
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => (
          <List
            height={height}
            itemCount={sortedPinIds.length}
            itemSize={CARD_HEIGHT}
            width={width}
            onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
              if (!isLoadingProperties && visibleStopIndex >= data.properties.size - 5) {
                loadMoreProperties(
                  data.parsedProperties.size + data.properties.size,
                  data.parsedProperties.size + data.properties.size + BATCH_SIZE
                );
              }
            }}
          >
            {Row}
          </List>
        )}
      </AutoSizer>
    );
  };

  return (
    <ListContainer>
      {content()}
    </ListContainer>
  );
};

export default PropertyList;
