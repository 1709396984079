import { FC } from 'react';
import MuiSlider from '@mui/joy/Slider';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

interface SliderProps {
    handleChange: (event: Event, newValue: number | number[]) => void;
    value: number;
}

const Slider: FC<SliderProps> = ({ handleChange, value }) => {

    return (
        <Box component='div' sx={{ width: '100%' }}>
            <Typography level='body-xs' sx={{ pl: 0, ml: 0 }}>Percentile</Typography>
            <MuiSlider 
                min={10} max={90} step={10} 
                marks valueLabelDisplay='auto'
                value={value} onChange={handleChange}
                sx={{
                    "--Slider-trackSize": "17px",
                    "--Slider-thumbWidth": "13px"
                }} 
            />
        </Box>

    )

}

export default Slider;