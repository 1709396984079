import { Renderer, Cluster } from '@googlemaps/markerclusterer';

export class CustomRenderer implements Renderer {
    public render = (
        { count, position }: Cluster,
    ): google.maps.Marker => {
        const zIndex: number = Number(google.maps.Marker.MAX_ZINDEX) + count;
        const iconSize = CustomRenderer.getIconSize(count);
        const markerOptions: google.maps.MarkerOptions = {
            position,
            zIndex,
            icon: {
                url: '/cluster-marker.png',
                scaledSize: new google.maps.Size(iconSize * 0.7, iconSize),
                labelOrigin: new google.maps.Point(iconSize * 0.7 / 2, iconSize / 2 - iconSize / 8)
            },
            label: {
                text: String(count),
                color: 'rgba(0, 0, 0, 0.9)',
                fontSize: '12px',
                fontWeight: 'bold',
            }
        };
        
        return new google.maps.Marker(markerOptions);
    }

    static getIconSize = (count: number) : number => {
        if (count < 2) return 25;
        return 20 + count.toString().length * 10;
    }

    // static getIcon = (count: number) : string => {
    //     return count < 10 ? '/marker-icon.png' : '/cluster-icon.png';
    // }

    private createClusterIcon = (count: number): HTMLElement => {
        const iconSize = CustomRenderer.getIconSize(count);
        // const iconUrl = CustomRenderer.getIcon(count);

        const icon = document.createElement('div');
        icon.style.width = `${iconSize}px`;
        icon.style.height = `${iconSize}px`;
        icon.style.backgroundImage = 'url(/cluster-marker.png)';
        icon.style.backgroundSize = 'cover';
        icon.textContent = count.toString();
        // icon.style.display = 'flex';
        // icon.style.justifyContent = 'center';
        // icon.style.alignItems = 'center';
        // icon.style.position = 'relative';
        return icon;
    }
}