import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import { styled } from '@mui/joy/styles';
import Container from '@mui/joy/Container';
import { login } from '../api/users.api';
import PersonRounded from '@mui/icons-material/PersonRounded';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100vh',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(15),
    gap: theme.spacing(3),
}));

const ImageBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    height: '200px',
    width: '350px',
    marginBottom: theme.spacing(3),
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.colorSchemes.light.palette.primary[500],
}));


const LoginPage = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [state, setState] = useState<{
        error: string | null,
        loading: boolean
    }>({
        error: null,
        loading: false
    })
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setState({ ...state, loading: true });

        try {
            const { token, error } = await login(username, password);
            if (error) {
                setState({ error, loading: false });
                return;
            }
            if (token) {
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                navigate('/')
            }
            throw new Error('unexpected response');
        } catch (err) {
            setState({ error: `An error occurred: ${err}`, loading: false });
        }

    }

    return (
        <StyledContainer>
            <Box component='div' sx={{ 
                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 2,
                borderRadius: 15, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)'
            }}>
                <ImageBox>
                    <img src='login_logo.svg' alt='logo' style={{ maxWidth: '80%', minWidth: '80%' }} />
                </ImageBox>
                <Box sx={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    marginBottom: '24px'
                }}>
                    <Typography level='h4'>Login</Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={4}>
                        <FormControl>
                            <Input
                                placeholder='username'
                                required
                                value={username}
                                type='email'
                                onChange={(event) => setUsername(event.target.value)}
                                error={!!state.error}
                                sx={{ width: '350px' }}
                                endDecorator={<PersonRounded />}
                            />
                        </FormControl>
                        <FormControl>
                            <Input
                                required
                                placeholder='password'
                                value={password}
                                type={showPassword ? 'text' : 'password'}
                                onChange={(event) => setPassword(event.target.value)}
                                error={!!state.error}
                                endDecorator={
                                    <IconButton size='sm' onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                }
                            />
                        </FormControl>
                        <Button
                            type='submit'
                            color='primary'
                            loading={state.loading}
                            disabled={state.loading}
                        >
                            Login
                        </Button>
                    </Stack>
                </form>
            </Box>


        </StyledContainer>
    )
}

export default LoginPage;