import { IFilter } from '../types/filter.type';
import { BASE_ENDPOINT } from './config';
import { urlJoin, stringifyFilter } from './utils';

export const getPins = async (filter: IFilter) => {
  // console.log('url', `${urlJoin(BASE_ENDPOINT, 'properties', 'pins')}?${stringifyFilter(filter)}`);
  try {
    const response = await fetch(`${urlJoin(BASE_ENDPOINT, 'properties', 'pins')}?${stringifyFilter(filter)}`);
    return await response.json();
  } catch (error) {
    console.error('Failed to fetch pins:', error);
    return null;
  }
};

export const getProperties = async (ids: string[], sortBy?: string, sortOrder?: string) => {
  try {
    const response = await fetch(urlJoin(BASE_ENDPOINT, 'properties'), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids,
        sort_by: sortBy,
        sort_order: sortOrder,
      }),
    });

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch properties:', error);
    return null;
  }
};

export const getProperty = async (id: string) => {
  try {
    const response = await fetch(`${urlJoin(BASE_ENDPOINT, 'properties', id)}`);
    return await response.json();
  } catch (error) {
    console.error(`Failed to fetch property ${id}`, error);
    return null;
  }
};

export const updateProperty = async (id: string, data: any, parse?: boolean) => {
  try {
    const response = await fetch(urlJoin(BASE_ENDPOINT, 'properties', id), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data, parse }),
    });
    return true;
  } catch (error) {
    console.error(`Failed to update property ${id}`, error);
    return false;
  }
};

export const getParseObject = (
  source: string = 'manual',
  website_name: string = 'seaforthresi',
  url: string = 'https://www.seaforthresi.com/',
  address: string,
  region: string = 'Greater London',
  area?: number | null,
  price?: number | null,
  postcode?: string,
  article4?: boolean | null,
  tenure?: string | null,
  listedGrade?: string | null,
  tags?: string[],
  description?: string,
  geography_dict?: { lat: number; lng: number } | null,
  location_accuracy?: number
) => {
  let parseObject: Record<string, any> = {
    source,
    website_name,
    url,
    scraped_address: address,
    region,
  };
  if (area) parseObject['area'] = { sq_ft: [area, area] };
  if (price) parseObject['price'] = price;
  if (postcode) parseObject['postcode'] = postcode;
  if (article4) parseObject['article4'] = article4;
  if (tenure) parseObject['tenure'] = tenure.toLowerCase();
  if (listedGrade) parseObject['listed_grade'] = listedGrade;
  if (tags) parseObject['tags'] = tags;
  if (description) parseObject['description'] = description;
  if (geography_dict) parseObject['geography_dict'] = geography_dict;
  if (location_accuracy) parseObject['location_accuracy'] = location_accuracy;
  return parseObject;
};

export const parseProperties = async (items: any[], saveToDb: boolean) => {
  try {
    console.log(saveToDb);
    const response = await fetch(urlJoin(BASE_ENDPOINT, 'properties', 'parse'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ items, saveToDb }),
    });

    return await response.json();
  } catch (error) {
    console.error('Failed to parse properties:', error);
    return [];
  }
};

export const parsePropertiesFromFile = async (
  file: File,
  saveToDb: boolean,
  fileType: 'Costar' | 'Manual',
  tags: string[],
  tenure: string | null,
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('saveToDb', saveToDb.toString());
  formData.append('fileType', fileType);
  formData.append('tags', JSON.stringify(tags));
  if (tenure) formData.append('tenure', tenure.toLowerCase());
  try {
    const response = await fetch(urlJoin(BASE_ENDPOINT, 'properties', 'parse-file'), {
      method: 'POST',
      body: formData,
    });
    return await response.json();
  } catch (error) {
    console.error('Failed to parse properties from file:', error);
    return [];
  }
};
