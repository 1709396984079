import { useContext } from 'react';
import { FilterContext } from '../contexts/Filter';
import { IFilterContext } from '../types/filter.type';

const useFilter = (): IFilterContext => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilter must be used within a FilterProvider');
    }
    return context;
}

export default useFilter;