import { extendTheme } from "@mui/joy";
import { createTheme } from "@mui/material/styles";

export const joyTheme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    500: '#527196'
                },
                divider: 'rgba(0, 0, 0, 0.12)',
            }
        }
    },
});

export const muiTheme = createTheme();