import { useState, useCallback } from 'react';
import Box from '@mui/joy/Box';
import RentMetricCard from './RentMetricCard';
import Skeleton from '@mui/joy/Skeleton';
import usePropData from '../hooks/usePropData';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import PsfDistribution from './PsfDistribution';
import Slider from './Slider';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { PropertyTypes } from '../types/prop-data.type';
import IconButton from '@mui/joy/IconButton';
import RestartAltRounded from '@mui/icons-material/RestartAltRounded';
import { getMsoaData, getMsoaMetrics, getMsoaPsfDist } from '../api/metrics.api';
import { utils, writeFile } from 'xlsx';
import { getCurrentTimestamp } from '../api/utils';

const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

const Metrics = () => {
    const [placement, setPlacement] = useState<'metrics' | 'psf-dist'>('metrics');
    const { state, dispatch } = usePropData();
    const [isSearch, setIsSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { metrics } = state;
    const [percentile, setPercentile] = useState(60);
    const handleChange = (event: Event, newValue: number | number[]) => {
        setPercentile(newValue as number);
    }

    const handlePropertyTypesChange = (
        event: React.SyntheticEvent | null, newValue: Array<string> | null
    ) => {
        if (newValue) {
            setIsSearch(true);
            if (!newValue.includes('All')) {
                dispatch({ type: 'SET_METRIC_FILTER', payload: { beds: state.metricFilter.beds, propertyTypes: newValue } });
            }
        }
    }

    const handleBedsChange = (
        event: React.SyntheticEvent | null, newValue: number | null
    ) => {
        if (newValue) {
            setIsSearch(true);
            dispatch({ type: 'SET_METRIC_FILTER', payload: { propertyTypes: state.metricFilter.propertyTypes, beds: newValue } });
        }
    }

    const handleDownload = useCallback(async () => {
        const codes = metrics.map((metric: any) => metric.code);
        const data = await getMsoaData(codes, sixMonthsAgo.toISOString(), new Date().toISOString(), state.metricFilter.propertyTypes, state.metricFilter.beds);

        if (data) {
            const { rental, sale } = data;
            const ws1 = utils.json_to_sheet(rental.flat());
            const ws2 = utils.json_to_sheet(sale.flat());

            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws1, "Rental");
            utils.book_append_sheet(wb, ws2, "Sale");

            writeFile(wb, `rental_sale_${getCurrentTimestamp()}.xlsx`);

        } else {
            // error modal
        }
    }, [metrics]);

    const handleSearch = useCallback(async () => {
        setIsLoading(true);
        const codes = metrics.map((metric: any) => metric.code);
        const newMetrics = await getMsoaMetrics(codes, sixMonthsAgo.toISOString(), new Date().toISOString(), state.metricFilter.propertyTypes, state.metricFilter.beds);
        newMetrics.forEach((metric: any) => {
            const { code } = metric;
            metric.name = metrics.find((m: any) => m.code === code).name;
        })
        dispatch({ type: 'SET_METRICS', payload: newMetrics });

        const psfDist = await getMsoaPsfDist(codes);
        dispatch({ type: 'SET_PSF_DIST', payload: psfDist });
        setIsLoading(false);
    }, [metrics, state.metricFilter.propertyTypes, state.metricFilter.beds]);

    return (
        <Tabs sx={{ height: '100%', display: 'flex', flexDirection: 'column' }} variant='plain' value={placement} onChange={(e, newValue) => setPlacement(newValue as typeof placement)}>
            <TabList underlinePlacement='bottom' sticky='top' sx={{ width: '100%', '& .MuiTab-root': { flex: 1 } }}>
                <Tab value='metrics'>Metrics</Tab>
                <Tab value='psf-dist'>PSF Distribution</Tab>
            </TabList>

            {/* metrics panel */}
            <TabPanel value='metrics' sx={{ overflowY: 'auto', padding: 1, '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollBarWidth: 'none' }}>
                <Typography level='body-xs'>Date Range: 6 Months ago</Typography>
                {/*  filters */}
                <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, paddingBottom: 0 }}>
                    <Slider handleChange={handleChange} value={percentile} />
                </Box>
                <Stack direction='row' spacing={1} sx={{ display: 'flex', alignItmes: 'center', p: 2, paddingBottom: 0 }}>
                    <Box sx={{ width: '50%', maxWidth: '170px' }}>
                        <Typography level='body-xs'>Property Types</Typography>
                        <Select
                            multiple
                            value={state.metricFilter.propertyTypes}
                            onChange={handlePropertyTypesChange}

                            slotProps={{
                                listbox: {
                                    sx: { width: '100%' }
                                }
                            }}
                        >
                            {PropertyTypes.map((type) => <Option key={type} value={type}>{type}</Option>)}
                        </Select>
                    </Box>
                    <Box sx={{ width: '50%', }}>
                        <Typography level='body-xs'>Beds</Typography>
                        <Select
                            value={state.metricFilter.beds}
                            onChange={handleBedsChange}
                            slotProps={{
                                listbox: {
                                    sx: { width: '100%' }
                                }
                            }}
                        >
                            {Array.from({ length: 10 }).map((_, i) => <Option key={i} value={i}>{i}</Option>)}
                        </Select>
                    </Box>
                    <IconButton size='sm' onClick={() => {
                        dispatch({ type: 'RESET_METRIC_FILTER' });
                    }}>
                        <RestartAltRounded />
                    </IconButton>
                </Stack>
                {isSearch && <Box component='div' sx={{ padding: 2 }} onClick={() => handleSearch()}>
                    <Button variant='outlined' fullWidth loading={isLoading}>Search</Button>
                </Box>}

                {/* display metrics */}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1}}>
                    {(!metrics || metrics.length === 0)
                        ? (Array.from({ length: 20 }).map((_, i) => (<Skeleton key={i} variant="text" width={`${Math.min(Math.floor(Math.random() * 100) + 20, 80)}%`} />)))
                        : (metrics.map((metric: any) => <RentMetricCard key={metric.code} metric={metric} percentile={percentile} />))}
                </Box>

                {/* download properties */}
                <Box component='div' sx={{ padding: 2 }} onClick={() => handleDownload()}>
                    <Button variant='outlined' fullWidth>Download</Button>
                </Box>
            </TabPanel>

            {/* psf distribution panel */}
            <TabPanel value='psf-dist' sx={{ overflowY: 'auto', padding: 1 }}>
                <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, paddingBottom: 0 }}>
                    <Slider handleChange={handleChange} value={percentile} />
                </Box>
                <PsfDistribution percentile={percentile} />
            </TabPanel>

        </Tabs>
    );
}

export default Metrics;