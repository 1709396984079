import React, { FC, memo, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useJsApiLoader, Libraries } from '@react-google-maps/api';
import usePropData from '../hooks/usePropData';
import { getParseObject, parseProperties, parsePropertiesFromFile } from '../api/properties.api';
import { addTag } from '../api/tags.api';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import IconButton from '@mui/joy/IconButton';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Input from '@mui/joy/Input';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import { styled } from '@mui/joy/styles';
import Grid from '@mui/joy/Grid';
import Divider from '@mui/joy/Divider';
import FileUploadButton from '../components/FileUploadButton';
import Textarea from '@mui/joy/Textarea';
import Chip from '@mui/joy/Chip';
import CloseIcon from '@mui/icons-material/Close';
import NumericInput from '../components/NumberInput';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import CircularProgress from '@mui/joy/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

const username = localStorage.getItem('username');
const [firstName, lastName] = username?.split('@')[0]?.split('.') ?? ['A', 'U'];
const FirstName = firstName === 'calvin' ? 'Admin' : firstName.charAt(0).toUpperCase() + firstName.slice(1);

const StyledButton = styled(Button)(({ theme }) => ({
  [`&[aria-pressed='true']`]: {
    ...theme.variants.outlinedActive.primary,
    borderColor: theme.vars.palette.primary.outlinedHoverBorder,
  },
}));

const getAddressComponentsMap = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const addressComponentsMap: { [key: string]: string } = {};
  addressComponents.forEach((component) => {
    addressComponentsMap[component.types[0]] = component.long_name;
  });
  return addressComponentsMap;
};

const libraries: Libraries = ['places'];
const article4Options = ['Unsure', 'Restricted', 'Unrestricted'];

// header component
const Header: FC<{ handleLogout: () => void }> = memo(({ handleLogout }) => {
  const username = localStorage.getItem('username');
  const [firstName, lastName] = username?.split('@')[0]?.split('.') ?? ['A', 'U'];
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <h1>Add New Properties</h1>
      <Stack direction="row" spacing={2} sx={{ ml: 'auto' }}>
        <Avatar>
          {firstName[0].toUpperCase()}
          {lastName[0].toUpperCase()}
        </Avatar>
        <IconButton size="md" onClick={handleLogout}>
          <LogoutRounded />
        </IconButton>
      </Stack>
    </Box>
  );
});

// select search or upload file component
const SelectOption: FC<{
  handleOptionSelect: (option: 'search' | 'upload') => void;
}> = memo(({ handleOptionSelect }) => (
  <>
    <Button
      variant="outlined"
      color="neutral"
      sx={{ height: '100%', width: '50%' }}
      onClick={() => handleOptionSelect('search')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {['Search Google Maps', 'or', 'Enter Address Manually'].map((text, index) => (
          <Typography key={index} level="title-lg" fontSize="28px" color="neutral">
            {text}
          </Typography>
        ))}
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <TravelExploreIcon sx={{ width: '28px', height: '28px' }} />
          <EditLocationAltIcon sx={{ width: '28px', height: '28px' }} />
        </Box>
      </Box>
    </Button>
    <Button
      variant="outlined"
      color="neutral"
      sx={{ height: '100%', width: '50%' }}
      onClick={() => handleOptionSelect('upload')}
    >
      <Typography level="title-lg" fontSize="28px" startDecorator={<UploadFileIcon />} color="neutral">
        Upload File
      </Typography>
    </Button>
  </>
));

const ParsePage: FC = () => {
  const [searchInput, setSearchInput] = useState<HTMLInputElement | null>(null);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAfTwgKakiL0kTBPEW6cbGq8_O8HAp8Wvg',
    libraries: libraries,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [address, setAddress] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [postTown, setPostTown] = useState<string>('');
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [article4Index, setArticle4Index] = useState<number>(0);
  const [price, setPrice] = useState<number | null>(null);
  const [area, setArea] = useState<number | null>(null);
  const [tenure, setTenure] = useState<string | null>(null);
  const [listedGrade, setListedGrade] = useState<'I' | 'II' | 'II*' | null>(null);
  const [description, setDescription] = useState<string>('');
  const [newTags, setNewTags] = useState<string[]>([FirstName]);
  const [isAddTag, setIsAddTag] = useState(false);
  const [addTagLoading, setAddTagLoading] = useState(false);
  const [fileType, setFileType] = useState<'Costar' | 'Manual'>('Costar');
  const [option, setOption] = useState<'search' | 'upload' | null>(null);
  const [saveToDb, setSaveToDb] = useState(false);
  const [open, setOpen] = useState(false);
  const [parsing, setParsing] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { state, dispatch } = usePropData();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/login');
  }, []);

  const handleOptionSelect = (option: 'search' | 'upload') => {
    if (option === 'search') {
      setActiveStep(1);
    }
    if (option === 'upload') {
      if (fileType === 'Costar' && !newTags.includes('costar')) {
        setNewTags((prev) => [...prev, 'costar']);
      }
      setActiveStep(2);
    }
    setOption(option);
  };

  const handleAddTagInputKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const value = event.currentTarget.value;
      let newTag = Array.from(state.currentTags.values()).find((tag: any) => tag.name === value);
      if (!newTag) {
        setAddTagLoading(true);
        newTag = await addTag(value);
        dispatch({ type: 'ADD_TAG', payload: { tag_id: newTag.tag_id, name: newTag.name, created_by: username } });
        setAddTagLoading(false);
      }
      console.log(newTag, newTags);
      if (!newTags.includes(newTag.tag_id)) {
        setNewTags((prev) => [...prev, newTag.tag_id]);
      }
      setIsAddTag(false);
    }
  };

  const handleResetDetails = useCallback(() => {
    setArticle4Index(0);
    setTenure(null);
    setListedGrade(null);
    setNewTags([]);
    setSaveToDb(false);
  }, []);

  const handleGoBack = useCallback(() => {
    handleResetDetails();
    setActiveStep(0);
  }, []);

  const handleChangeFileType = (type: 'Costar' | 'Manual') => {
    if (type === 'Costar') {
      setNewTags((prev) => [...prev, 'costar']);
    } else {
      if (newTags.includes('costar')) {
        setNewTags((prev) => prev.filter((tag) => tag !== 'costar'));
      }
    }
    setFileType(type);
    setFile(null);
  };

  const addParsedResult = useCallback((parsedResult: any[]) => {
    dispatch({ type: 'ADD_PARSED_PROPERTIES', payload: parsedResult });
    const parsedPins = parsedResult.map((property: any) => ({
      property_id: property.property_id,
      geography: { type: 'Point', coordinates: [property.geography_dict.lng, property.geography_dict.lat] },
      scraped_address: property.scraped_address,
      parsed: true, // for distinguishing from fetched pins
    }));
    dispatch({ type: 'ADD_PARSED_PINS', payload: parsedPins });
  }, []);

  const handleParse = async () => {
    setError(null);
    const parseObject = getParseObject(
      'manual',
      'seaforthresi',
      'https://www.seaforthresi.com/',
      address,
      'Greater London',
      area,
      price,
      postcode,
      article4Index === 0 ? null : article4Index === 1,
      tenure,
      listedGrade,
      newTags,
      description,
      coordinates
    );

    let parsedResult;
    if (option === 'search') {
      if (!address) return;
      setParsing(true);
      setOpen(true);
      parsedResult = await parseProperties([parseObject], saveToDb);
    } else if (option === 'upload') {
      if (!file) {
        setParsing(false);
        setOpen(true);
        setError('Please upload a file.');
        return;
      }
      setParsing(true);
      setOpen(true);
      parsedResult = await parsePropertiesFromFile(file, saveToDb, fileType, newTags, tenure);
    }

    if (parsedResult && Array.isArray(parsedResult)) {
      console.log(parsedResult);
      !saveToDb && addParsedResult(parsedResult);
    } else {
      setError(parsedResult?.message || 'Failed to parse properties');
    }
    setParsing(false);
  };

  const searchRef = useCallback((node: HTMLInputElement | null) => {
    if (node !== null) {
      setSearchInput(node);
    }
  }, []);

  // add search results to gmap search
  useEffect(() => {
    if (isLoaded && searchInput && activeStep === 1) {
      const searchBox = new google.maps.places.SearchBox(searchInput);

      const londonBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(51.390135254061704, -0.31956741943361067),
        new google.maps.LatLng(51.59000977407033, 0.14185836181638933)
      );
      searchBox.setBounds(londonBounds);

      const placesChangedListener = searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();

        if (places && places.length > 0) {
          const place = places[0];
          if (place.address_components) {
            const components = getAddressComponentsMap(place.address_components);
            setAddress(place.formatted_address || '');
            setPostcode(components['postal_code'] || '');
            setPostTown(components['postal_town'] || '');
            if (place.geometry && place.geometry.location) {
              setCoordinates({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            }
          } else {
            console.error('No address components found');
          }
        }
      });

      return () => {
        google.maps.event.removeListener(placesChangedListener);
      };
    }
  }, [activeStep, isLoaded, searchInput]);

  const TagAdder: FC = () => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
      {state.currentTags.size > 0 &&
        Array.from(state.currentTags.values()).map((tag: any) => (
          <Chip
            key={tag.tag_id}
            disabled={parsing || !saveToDb}
            variant="outlined"
            size="md"
            sx={{ mr: 1 }}
            color={newTags.includes(tag.tag_id) ? 'primary' : 'neutral'}
            onClick={() => {
              newTags.includes(tag.tag_id)
                ? setNewTags((prev) => prev.filter((tagId) => tagId !== tag.tag_id))
                : setNewTags((prev) => [...prev, tag.tag_id]);
            }}
          >
            {tag.name}
          </Chip>
        ))}
      {!isAddTag && (
        <Chip variant="outlined" size="md" onClick={() => setIsAddTag(true)} disabled={parsing || !saveToDb}>
          Create Custom Tag
        </Chip>
      )}
      {isAddTag && (
        <Input
          autoFocus
          onKeyDown={handleAddTagInputKeyDown}
          placeholder="Press Enter to Confirm"
          size="sm"
          endDecorator={
            addTagLoading ? (
              <CircularProgress size="sm" />
            ) : (
              <IconButton onClick={() => setIsAddTag(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            )
          }
          sx={{ width: '200px' }}
          slotProps={{ input: { sx: { '--Input-height': '10px' } } }}
        />
      )}
    </Box>
  );

  const SelectTenure: FC = () => (
    <Box>
      <Typography level="body-xs" mb={0.5}>
        Tenure
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {['Freehold', 'Virtual Freehold', 'Leasehold'].map((text, index) => (
          <StyledButton
            key={index}
            disabled={parsing}
            sx={{ minWidth: '100px', maxWidth: '200px' }}
            variant="outlined"
            color="neutral"
            aria-pressed={tenure === text}
            onClick={() => setTenure(text)}
          >
            {text}
          </StyledButton>
        ))}
      </Box>
    </Box>
  );

  const Configuration: FC = () => (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography level="body-sm" sx={{ color: '#000', fontWeight: 'bold' }}>
        Configuration
      </Typography>
      <Typography level="body-xs">Save to Database?</Typography>
      {!saveToDb && (
        <Typography level="body-xs" color="warning">
          (Properties will be lost after refreshing. Bookmarking and tagging features are disabled.)
        </Typography>
      )}
      <Box sx={{ display: 'flex', gap: 2 }}>
        {['Yes', 'No'].map((text, index) => (
          <StyledButton
            key={index}
            disabled={parsing}
            sx={{ width: '50px' }}
            variant="outlined"
            color="neutral"
            aria-pressed={saveToDb === (text === 'Yes')}
            onClick={() => {
              setSaveToDb(text === 'Yes');
            }}
          >
            {text}
          </StyledButton>
        ))}
      </Box>
    </Box>
  );

  const ParseModal: FC = () => (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog size="lg">
        <ModalClose />
        {parsing && !error && (
          <>
            <DialogTitle sx={{ width: '400px' }}>
              <Stack direction="row" gap={2}>
                <CircularProgress size="sm" />
                <Typography level="body-lg">Parsing</Typography>
              </Stack>
            </DialogTitle>
            <DialogContent>
              {address}
              <Typography color="warning" level="body-xs">
                Please stay on this page until parsing is complete.
              </Typography>
            </DialogContent>
          </>
        )}
        {!parsing && !error && (
          <>
            <DialogTitle>
              <Stack direction="row" gap={2}>
                <CheckCircleIcon color="primary" fontSize="small" />
                <Typography level="body-lg">Parsing Successful</Typography>
              </Stack>
            </DialogTitle>
            <DialogContent sx={{ maxWidth: '400px' }}>
              <Typography level="body-sm">{`Return to Home page to view your parsed propert${
                option === 'search' ? 'y' : 'ies'
              }.`}</Typography>
              {!saveToDb && (
                <Typography
                  level="body-xs"
                  color="warning"
                  startDecorator={<WarningOutlinedIcon fontSize="small" sx={{ mr: 1 }} />}
                  sx={{ textWrap: 'wrap', alignItems: 'center', mt: 1 }}
                >
                  Properties will be lost upon refreshing. Select save to database for persistent results.
                </Typography>
              )}
            </DialogContent>
          </>
        )}
        {error && (
          <Typography level="body-sm" sx={{ color: 'error' }}>
            {error}
          </Typography>
        )}
      </ModalDialog>
    </Modal>
  );

  return (
    <Box component="div" sx={{ width: '100%', height: '100%' }}>
      <Header handleLogout={handleLogout} />
      {/* Main Content */}
      <Box
        id="container"
        sx={{
          flexGrow: 1,
          height: '80%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <ParseModal />

        {/* 0: Option Selection */}
        {activeStep === 0 && <SelectOption handleOptionSelect={handleOptionSelect} />}

        {/* Option 1: Manual */}
        {activeStep === 1 && (
          <form
            onSubmit={(e) => e.preventDefault()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {/* Gmap Search */}
              <Box sx={{ width: '100%', mb: 2, display: 'flex', gap: 1 }}>
                <Input
                  placeholder="Google Maps Search"
                  disabled={!isLoaded || parsing}
                  sx={{ width: '400px', height: '32px' }}
                  slotProps={{ input: { ref: searchRef } }}
                />
                {parsing && (
                  <IconButton onClick={() => setOpen(true)}>
                    <CircularProgress size="sm" />
                  </IconButton>
                )}
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />

              {/* Details Form */}
              <Grid container sx={{ width: '100%', height: '100%', ml: 0, mr: 0 }}>
                <Grid md={6} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 4,
                      width: '100%',
                    }}
                  >
                    {/* Address  */}
                    <Box>
                      <Typography level="body-sm" sx={{ color: '#000', fontWeight: 'bold' }} mb={1}>
                        Address
                      </Typography>
                      <Stack rowGap={2}>
                        <Input
                          sx={{ width: '400px' }}
                          required
                          disabled={parsing}
                          placeholder="Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                        <Input
                          sx={{ width: '400px' }}
                          disabled={parsing}
                          placeholder="Postcode"
                          value={postcode}
                          onChange={(e) => setPostcode(e.target.value)}
                        />
                        <Input
                          sx={{ width: '400px' }}
                          disabled={parsing}
                          placeholder="Post Town"
                          value={postTown}
                          onChange={(e) => setPostTown(e.target.value)}
                        />
                      </Stack>
                    </Box>

                    {/* Details */}
                    <Box>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Typography level="body-sm" sx={{ color: '#000', fontWeight: 'bold' }} mb={1}>
                          Details
                        </Typography>
                        <Button
                          variant="plain"
                          size="sm"
                          disabled={parsing}
                          sx={{
                            mb: 1,
                            minHeight: 0,
                            padding: '0 8px',
                            lineHeight: 'inherit',
                          }}
                          onClick={handleResetDetails}
                        >
                          Reset
                        </Button>
                      </Box>
                      <Stack rowGap={2}>
                        {/* Article 4 */}
                        <Box>
                          <Typography level="body-xs" mb={0.5}>
                            Article4 (Click to Cycle Options)
                          </Typography>
                          <StyledButton
                            disabled={parsing}
                            sx={{ width: '200px' }}
                            variant="outlined"
                            color="neutral"
                            aria-pressed={article4Index !== 0}
                            onClick={() => setArticle4Index((prevIndex) => (prevIndex + 1) % article4Options.length)}
                          >
                            {article4Options[article4Index]}
                          </StyledButton>
                        </Box>

                        {/* Tenure */}
                        <SelectTenure />

                        {/* Listed Grade */}
                        <Box>
                          <Typography level="body-xs" mb={0.5}>
                            Listed Grade
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 2 }}>
                            {['I', 'II', 'II*'].map((text, index) => (
                              <StyledButton
                                key={index}
                                disabled={parsing}
                                sx={{ width: '50px' }}
                                variant="outlined"
                                color="neutral"
                                aria-pressed={listedGrade === text}
                                onClick={() => setListedGrade(text as 'I' | 'II' | 'II*')}
                              >
                                {text}
                              </StyledButton>
                            ))}
                          </Box>
                        </Box>
                      </Stack>
                    </Box>

                    {/* Navigation */}
                    <Stack direction="row" gap={2} mt={2}>
                      <Button variant="outlined" sx={{ width: '300px' }} onClick={handleGoBack} disabled={parsing}>
                        Go Back
                      </Button>
                      <Button type="submit" sx={{ width: '300px' }} onClick={handleParse} disabled={parsing}>
                        Parse
                      </Button>
                    </Stack>
                  </Box>
                </Grid>

                <Grid md={6} xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 4,
                      width: '100%',
                      maxWidth: '100%',
                    }}
                  >
                    {/* Brochures and Floorplans */}
                    <Box>
                      <Typography level="body-sm" sx={{ color: '#000', fontWeight: 'bold' }} mb={1.3}>
                        Files (Feature Available Soon)
                      </Typography>
                      <Stack direction="row" gap={2}>
                        <FileUploadButton disabled title="Brochure" width="317px" allowedExtensions={['.pdf']} />
                        <FileUploadButton disabled title="Floorplan" width="317px" allowedExtensions={['.pdf']} />
                      </Stack>
                    </Box>

                    {/* Extras */}
                    <Box>
                      <Typography level="body-sm" sx={{ color: '#000', fontWeight: 'bold' }} mb={1.3}>
                        Extras
                      </Typography>
                      <Stack rowGap={3}>
                        <Box>
                          <Typography level="body-xs">Tags (Click to Select/Unselect)</Typography>
                          <TagAdder />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <NumericInput disabled={parsing} label="Area (sqft)" value={area} onChange={setArea} />
                          <NumericInput disabled={parsing} label="Price (£)" value={price} onChange={setPrice} />
                        </Box>
                        <Textarea
                          disabled={parsing}
                          minRows={1}
                          maxRows={3}
                          placeholder="Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Stack>
                    </Box>

                    {/* Configuration */}
                    <Configuration />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}

        {/* Option 2: Upload File */}
        {activeStep === 2 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, width: '100%' }}>
            <Box>
              {/* Select file type */}
              <Typography level="body-sm" sx={{ color: '#000', fontWeight: 'bold', mb: 1 }}>
                Select a File Type
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {['Costar', 'Manual'].map((text, index) => (
                  <StyledButton
                    disabled={parsing}
                    key={index}
                    sx={{ minWidth: '100px', maxWidth: '300px' }}
                    variant="outlined"
                    color="neutral"
                    aria-pressed={fileType === text}
                    onClick={() => handleChangeFileType(text as 'Costar' | 'Manual')}
                  >
                    {text === 'Costar' ? 'Costar (.xlsx)' : 'Manual (.xlsx, .csv, .json, .jl)'}
                  </StyledButton>
                ))}
                {parsing && <IconButton onClick={() => setOpen(true)}>{<CircularProgress size="sm" />}</IconButton>}
              </Box>
            </Box>
            {fileType === 'Costar' && (
              <Typography level="body-xs" startDecorator={<WarningOutlinedIcon fontSize="small" />}>
                For Costar files, ensure the address column is included. Including latitude and longitude columns are
                also preferred.
              </Typography>
            )}
            {fileType === 'Manual' && (
              <Typography level="body-xs" startDecorator={<WarningOutlinedIcon fontSize="small" />}>
                For manual files, ensure only the following headers are provided and are not missing: source,
                website_name, url, scraped_address, region.
              </Typography>
            )}
            <Divider />

            {/* Tags */}
            <Box>
              <Typography level="body-xs">Tags (Click to Select/Unselect)</Typography>
              <TagAdder />
            </Box>
            
            {/* Tenure */}
            <SelectTenure />

            {/* Configuration */}
            <Configuration />

            {/* Upload file */}

            <FileUploadButton
              // title={fileType + ' File'}
              allowedExtensions={['.xlsx', '.csv', '.json', '.jl']}
              disabled={parsing}
              onFileSelect={(file) => {
                if (file) setFile(file);
              }}
              width="400px"
            />

            {/* Navigation */}
            <Stack direction="row" gap={2} mt={2}>
              <Button variant="outlined" sx={{ width: '300px' }} onClick={handleGoBack}>
                Go Back
              </Button>
              <Button sx={{ width: '300px' }} disabled={parsing} onClick={handleParse}>
                Parse
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ParsePage;
