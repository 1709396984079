import { urlJoin } from "./utils"
import { BASE_ENDPOINT } from "./config"

export const authenticateToken = async (token: string) => {
    try {
        const response = await fetch(urlJoin(BASE_ENDPOINT, 'users', 'authenticateToken'), {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return response.ok
    } catch (error) {
        console.error('Failed to authenticate user token', error);
        return false
    }
}

export const login = async (username: string, password: string) => {
    try {

        const response = await fetch(urlJoin(BASE_ENDPOINT, 'users', 'login'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        })
        console.log(response);
        if (!response.ok) {
            const error = await response.json().catch(() => null);
            return { error }
        }
        const { token } = await response.json();
        return { token }
    } catch (error) {
        console.error('Failed to login', error);
        return { error }
    }

}