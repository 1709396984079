import { useState } from 'react';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Avatar from '@mui/joy/Avatar';
import FilterMenu from '../components/FilterMenu';
import PropertyList from '../components/PropertyList';
import Metrics from '../components/Metrics';
import PropertyDetail from '../components/PropertyDetail';
import Map from '../components/Map';
import useFilter from '../hooks/useFilter';
import usePropData from '../hooks/usePropData';
import { styled } from '@mui/joy/styles';
import Stack from '@mui/joy/Stack';
import IconButton from '@mui/joy/IconButton';
import LogoutRounded from '@mui/icons-material/LogoutRounded';
import { useNavigate } from 'react-router-dom';

const RowContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    marginBottom: '24px',
    alignItems: 'center',
}));



const HomePage = () => {
    const navigate = useNavigate();
    const { dispatch: dispatchFilter } = useFilter();
    const { state, dispatch: dispatchData } = usePropData();
    const [filterOpen, setFilterOpen] = useState(false);

    const { showMetrics, selectedProperty } = state;

    const handleChange = (
        event: React.SyntheticEvent | null,
        newValue: string | null,
    ) => {
        const [sortBy, sortOrder] = newValue?.split('-') ?? ['date_added', 'DESC'];
        dispatchFilter({ type: 'SET_SORT_BY', payload: sortBy });
        dispatchFilter({ type: 'SET_SORT_ORDER', payload: sortOrder });
        dispatchData({ type: 'CLEAR_PROPERTIES' });
    }

    const handleClose = () => {
        dispatchData({ type: 'SET_SELECTED_PROPERTY', payload: null });
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        navigate('/login');
    }

    const Header = () => {
        const username = localStorage.getItem('username');
        const [firstName, lastName] = username?.split('@')[0].split('.') ?? ['', ''];
        return (
            <Box component='div' sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <h1>Map Search</h1>
                <Stack direction='row' spacing={2} sx={{ ml: 'auto'}}>
                    <Avatar>{firstName[0].toUpperCase()}{lastName[0].toUpperCase()}</Avatar>
                    <IconButton 
                        size='md'
                        onClick={handleLogout}
                    >
                        <LogoutRounded />
                    </IconButton>
                </Stack>
            </Box>
        )
    }


    return (
        <Box component='div' sx={{ width: '100%' }}>
            {/* <h1>Map Search</h1> */}
            <Header />
            <RowContainer>
                <Input
                    placeholder='Property Search'
                    sx={{ flexGrow: 1, minWidth: '200px' }}
                    disabled
                />
                <Select defaultValue="date_added-DESC" size='md' onChange={handleChange} sx={{ minWidth: '150px' }}>
                    <Option value='date_added-DESC'>Newest</Option>
                    <Option value='date_added-ASC'>Oldest</Option>
                    <Option value='price-ASC'>Price Low</Option>
                    <Option value='price-DESC'>Price High</Option>
                    <Option value='min_area-ASC'>Area Low</Option>
                    <Option value='min_area-DESC'>Area High</Option>
                </Select>

                <Button
                    variant='outlined'
                    color='neutral'
                    onClick={() => setFilterOpen(!filterOpen)}
                    endDecorator={`(${state.pins.length})`}
                    sx={{ minWidth: '150px' }}
                >
                    Filters
                </Button>
            </RowContainer>

            <Grid container spacing={1} sx={{ height: 'calc(100vh - 160px)', overflow: 'hidden', ml: 0, mr: 0}}>
                {/* Map Display */}
                <Grid xs={12} md={8} sx={{ height: '100%', position: 'relative' }} >
                    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                        <Map />
                        {selectedProperty && (
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                width: '40%',
                                height: '100%',
                                padding: 0.5,
                                backgroundColor: 'transparent',
                                zIndex: 53,
                            }}
                            >
                                <PropertyDetail onClose={handleClose}/>
                            </Box>
                        )}
                    </Box>
                </Grid>

                {/* Control Panel */}
                <Grid xs={12} md={4} sx={{ height: '100%', overflow: 'hidden', padding: 0  }}>
                    {showMetrics && <Metrics />}
                    {!showMetrics && filterOpen && <FilterMenu />}
                    {!showMetrics && !filterOpen && <PropertyList />}
                </Grid>
            </Grid>
        </Box>
    );
}

export default HomePage;