import { FC } from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedRoundedIcon from '@mui/icons-material/BookmarkAddedRounded';
import LocationOnRounded from '@mui/icons-material/LocationOnRounded'; // visibility on
import Stack from '@mui/joy/Stack';

import ImageCarousel from './ImageCarousel';


interface PropertyCardProps {
    property: any;
    handleClick: (propId: string) => void;
    handleViewOnMap: (propId: string) => void;
    handleBookmark: (propId: string) => void;
    savable?: boolean;
}
const PropertyCard: FC<PropertyCardProps> = ({
    property,
    handleClick,
    handleViewOnMap,
    handleBookmark,
    savable = true
}) => {

    const { property_id, scraped_address, min_area, max_area, min_psf, max_psf, price, saved, images } = property;
    const area = min_area === null ? 'Unknown area' : `${min_area.toLocaleString()}-${max_area.toLocaleString()} sqft`;
    const psf = min_psf === null ? 'Unknown psf' : `${min_psf.toLocaleString()}-${max_psf.toLocaleString()} £/sqft`;
    const priceStr = price === null ? 'Unknown price' : price.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });

    return (
        <Card sx={{ width: '100%', }}>
            <div>
                <Typography level="title-lg" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '80%' }}>{scraped_address}</Typography>
                <Typography level="body-sm">{`${area} | ${psf}`}</Typography>
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    disabled={!savable}
                    sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
                    onClick={() => handleBookmark(property_id)}
                >
                    {saved ? <BookmarkAddedRoundedIcon /> : <BookmarkAdd />}
                </IconButton>
            </div>
            <div style={{ minHeight: '120px', maxHeight: '200px' }}>
                <ImageCarousel images={images ?? ['/image_unavailable.jpg']} />
            </div>
            <CardContent orientation="horizontal" sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <Typography level="body-xs">Price:</Typography>
                    <Typography fontSize="lg" fontWeight="lg">{priceStr}</Typography>
                </div>
                <Stack direction='row' spacing={1}>
                    <IconButton size='sm' onClick={() => handleViewOnMap(property_id)}>
                        <LocationOnRounded />
                    </IconButton>
                    <Button
                        variant="solid"
                        size="sm"
                        color="primary"
                        sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
                        onClick={() => handleClick(property.property_id)}
                    >
                        Explore
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default PropertyCard;