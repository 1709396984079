import { BASE_ENDPOINT } from './config';
import { urlJoin } from './utils';
import { nanoid } from 'nanoid';

export const  getTags = async () => {
    try {
        const response = await fetch(urlJoin(BASE_ENDPOINT, 'tags'));
        return await response.json();
    } catch (error) {
        console.error('Failed to get tags:', error);
        return [];
    }
}

export const addTag = async (name: string) => {
    try {
        const username = localStorage.getItem('username');
        const response = await fetch(urlJoin(BASE_ENDPOINT, 'tags', 'create'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                tag_id: nanoid(),
                name,
                created_by: username ?? 'unknown user'
            })
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to add tag:', error);
        return false;
    }
}

export const deleteTag = async (tagId: string) => {
    try {
        const response = await fetch(urlJoin(BASE_ENDPOINT, 'tags', tagId), {
            method: 'DELETE',
        });
        return await response.json();
    } catch (error) {
        console.error('Failed to delete tag:', error);
        return [];
    }
}