import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';

type NumericInputProps = {
  label?: string;
  value: number | null;
  onChange: (value: number | null) => void;
  disabled?: boolean;
};

const NumericInput: React.FC<NumericInputProps> = ({ label, value, onChange, disabled }) => {
  const [inputValue, setInputValue] = useState(value?.toLocaleString() || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    setInputValue(rawValue ? parseInt(rawValue, 10).toLocaleString() : '');
    onChange(rawValue ? parseInt(rawValue, 10) : null);
  };

  return (
    <Box>
      {label && <Typography level="body-xs">{label}</Typography>}
      <Input
        size="sm"
        disabled={disabled}
        sx={{ width: '200px' }}
        value={inputValue}
        onChange={handleChange}
      />
    </Box>
  );
};

export default NumericInput;
